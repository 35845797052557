import React, { useEffect, useState } from 'react';

import s3 from '../s3';

export default function ({ description, path, title }) {
  const [url, setURL] = useState(null);

  useEffect(() => {
    let isSubscribed = true;

    if (url === null) {
      (async () => {
        try {
          const data = await s3.getObject(path);
          if (isSubscribed) {
            const blob = new Blob([data.Body], { type: data.ContentType });
            setURL(URL.createObjectURL(blob));
          }
        } catch (err) {
          console.log('Invalid S3 key:', path);
        }
      })();
    }

    return () => (isSubscribed = false);
  });

  return (
    <>
      <img alt="" src={url} style={styles.image} />
      {description && (
        <span style={styles.description} title={title}>
          {description}
        </span>
      )}
    </>
  );
}

const styles = {
  description: {
    display: 'block',
  },
  image: {
    width: '240px',
  },
};
