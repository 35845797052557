import React, { useState } from 'react';

import BitArray from '../BitArray';
import { itemTypes, rarities } from '../itemConsts';
import ItemMaskGenerator from './ItemMaskGenerator';

const DEFAULT_PACK = 'com.knightsofvasteel.magwasmagicitemcompendium';

export default function ({ onAdd, onDelete, onEdit, statics }) {
  const [itemTypeFilter, setItemTypeFilter] = useState(itemTypes.mask);
  const [packSearch, setPackSearch] = useState('');
  const [rarityFilter, setRarityFilter] = useState(rarities.mask);

  const [search, setSearch] = useState('');
  const [tempStaticItem, setTempStaticItem] = useState(null);

  const filteredStatics = statics
    .filter(
      ({ itemType, rarity }) =>
        itemTypeFilter.hasAny(itemType) && rarityFilter.hasAny(rarity),
    )
    .filter(
      ({ description, discordUser, name, mechanics, requires, type }) =>
        description.indexOf(search) !== -1 ||
        (discordUser || '').indexOf(search) !== -1 ||
        name.indexOf(search) !== -1 ||
        mechanics.indexOf(search) !== -1 ||
        (requires || '').indexOf(search) !== -1 ||
        type.indexOf(search) !== -1,
    )
    .filter(({ pack }) =>
      packSearch === 'default'
        ? pack === DEFAULT_PACK
        : pack.indexOf(packSearch) !== -1,
    );

  return (
    <>
      {tempStaticItem === null && (
        <>
          <ItemMaskGenerator
            mask={itemTypeFilter}
            onGenerate={setItemTypeFilter}
            type="itemType"
          />
          <ItemMaskGenerator
            mask={rarityFilter}
            onGenerate={setRarityFilter}
            type="rarity"
          />
          <input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            style={styles.search}
            type="text"
            value={search}
          />
          <input
            onChange={(e) => setPackSearch(e.target.value)}
            placeholder="Pack Search..."
            style={styles.search}
            title="type 'default' for no pack"
            type="text"
            value={packSearch}
          />
          <button
            onClick={() =>
              setTempStaticItem({
                description: '',
                // discordUser (optional)
                imageIndex: '',
                index: null,
                itemType: '',
                mechanics: '',
                name: '',
                pack: DEFAULT_PACK,
                rarity: '',
                // requires (optional)
                type: 'magwas',
              })
            }
            type="button"
          >
            + Add Static Item
          </button>
          <div>
            <div>Count: {filteredStatics.length}</div>
            {filteredStatics.map((staticItem) => (
              <div
                key={`${staticItem.type}:${staticItem.index}`}
                onClick={() => setTempStaticItem({ ...staticItem })}
                style={styles.row}
              >
                {staticItem.type} - {staticItem.name} -{' '}
                <span title={rarities.decode(staticItem.rarity)}>
                  {staticItem.rarity}
                </span>
                :
                <span title={itemTypes.decode(staticItem.itemType)}>
                  {staticItem.itemType}
                </span>
              </div>
            ))}
          </div>
        </>
      )}
      {tempStaticItem !== null && (
        <>
          <ItemMaskGenerator
            mask={tempStaticItem.itemType}
            onGenerate={(itemType) =>
              setTempStaticItem({
                ...tempStaticItem,
                itemType: itemType.toString(),
              })
            }
            type="itemType"
          />
          <ItemMaskGenerator
            mask={tempStaticItem.rarity}
            onGenerate={(rarity) =>
              setTempStaticItem({
                ...tempStaticItem,
                rarity: rarity.toString(),
              })
            }
            type="rarity"
          />
          <div>
            <span>Type: </span>
            {tempStaticItem.index !== null ? (
              <span>{tempStaticItem.type}</span>
            ) : (
              <>
                <label style={styles.label}>
                  <span>5th Edition SRD</span>
                  <input
                    checked={tempStaticItem.type === 'srd'}
                    onChange={() =>
                      setTempStaticItem({ ...tempStaticItem, type: 'srd' })
                    }
                    type="checkbox"
                  />
                </label>
                <label style={styles.label}>
                  <span>Magwa&apos;s Magic Item Compendium</span>
                  <input
                    checked={tempStaticItem.type === 'magwas'}
                    onChange={() =>
                      setTempStaticItem({ ...tempStaticItem, type: 'magwas' })
                    }
                    type="checkbox"
                  />
                </label>
                <label style={styles.label}>
                  <span>Featured From Discord</span>
                  <input
                    checked={tempStaticItem.type === 'discord'}
                    onChange={() =>
                      setTempStaticItem({ ...tempStaticItem, type: 'discord' })
                    }
                    type="checkbox"
                  />
                </label>
              </>
            )}
          </div>
          <input
            onChange={(e) => {
              const value = e.target.value;
              setTempStaticItem({
                ...tempStaticItem,
                pack: value || DEFAULT_PACK,
              });
            }}
            placeholder="Pack ID"
            required
            style={styles.input}
            value={tempStaticItem.pack}
          />
          <input
            onChange={(e) => {
              const value = e.target.value.trim();
              setTempStaticItem({
                ...tempStaticItem,
                imageIndex:
                  value !== '' ? (Number.isNaN(+value) ? '' : +value) : '',
              });
            }}
            placeholder="Image Index (required)"
            required
            style={styles.input}
            value={tempStaticItem.imageIndex}
          />
          <input
            onChange={(e) =>
              setTempStaticItem({
                ...tempStaticItem,
                name: e.target.value,
              })
            }
            placeholder="Name (required)"
            required
            style={styles.input}
            value={tempStaticItem.name}
          />
          <textarea
            onChange={(e) => {
              setTempStaticItem({
                ...tempStaticItem,
                description: e.target.value,
              });
            }}
            placeholder="Description (required)"
            required
            rows="10"
            style={styles.textarea}
            value={tempStaticItem.description}
          />
          <textarea
            onChange={(e) => {
              setTempStaticItem({
                ...tempStaticItem,
                mechanics: e.target.value,
              });
            }}
            placeholder="Mechanics (required)"
            required
            rows="10"
            style={styles.textarea}
            value={tempStaticItem.mechanics}
          />
          <input
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === '') {
                // remove requires from the object if its not set
                const { requires, ...nextStaticItem } = tempStaticItem;
                setTempStaticItem(nextStaticItem);
              } else {
                setTempStaticItem({ ...tempStaticItem, requires: value });
              }
            }}
            placeholder="Requires (optional) i.e. Attunement, Spellcaster"
            style={styles.input}
            value={tempStaticItem.requires || ''}
          />
          <input
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === '') {
                // remove discordUser from the object if its not set
                const { discordUser, ...nextStaticItem } = tempStaticItem;
                setTempStaticItem(nextStaticItem);
              } else {
                setTempStaticItem({ ...tempStaticItem, discordUser: value });
              }
            }}
            placeholder="Discord User Name (optional) i.e. TheDigisaur, Magwa55"
            style={styles.input}
            value={tempStaticItem.discordUser || ''}
          />
          <button
            onClick={() => {
              if (
                !itemTypes.mask.hasAny(tempStaticItem.itemType) ||
                new BitArray(tempStaticItem.itemType).toFlags().length > 1 ||
                !rarities.mask.hasAny(tempStaticItem.rarity) ||
                new BitArray(tempStaticItem.rarity).toFlags().length > 1
              ) {
                window.error(
                  'Must choose a single item type, and a single rarity.',
                );
                return;
              }

              tempStaticItem.name = tempStaticItem.name.trim();
              if (tempStaticItem.name === '') {
                window.error('Name cannot be blank.');
                return;
              }

              tempStaticItem.description = tempStaticItem.description.trim();
              if (tempStaticItem.description === '') {
                window.error('Description cannot be blank.');
                return;
              }

              tempStaticItem.mechanics = tempStaticItem.mechanics.trim();
              if (tempStaticItem.mechanics === '') {
                window.error('Mechanics cannot be blank.');
                return;
              }

              tempStaticItem.pack = tempStaticItem.pack.trim();
              if (tempStaticItem.pack === '') {
                tempStaticItem.pack = DEFAULT_PACK;
              }

              if (tempStaticItem.imageIndex === '') {
                window.error('Image index cannot be blank.');
                return;
              }

              const index = tempStaticItem.index;
              delete tempStaticItem.index;

              if (index === null) {
                onAdd(tempStaticItem);
              } else {
                onEdit(tempStaticItem, index);
              }

              setTempStaticItem(null);
            }}
            type="button"
          >
            Save
          </button>
          <button onClick={() => setTempStaticItem(null)} type="button">
            Cancel
          </button>
          {tempStaticItem.index !== null && (
            <button
              onClick={() => {
                if (window.confirm('Delete?')) {
                  onDelete(tempStaticItem.type, tempStaticItem.index);
                  setTempStaticItem(null);
                }
              }}
              type="button"
            >
              Delete
            </button>
          )}
        </>
      )}
    </>
  );
}

const styles = {
  input: {
    display: 'block',
    width: '700px',
  },
  label: {
    cursor: 'pointer',
    display: 'inline-block',
    margin: '5px',
  },
  preview: {
    backgroundColor: '#eee',
    padding: '15px',
    whiteSpace: 'pre-wrap',
  },
  row: {
    borderBottom: '1px solid #000',
    cursor: 'pointer',
    padding: '10px',
  },
  search: {
    display: 'block',
  },
  textarea: {
    width: '100%',
  },
  token: {
    backgroundColor: '#eee',
    margin: '0 5px',
    padding: '0 5px',
  },
  warning: {
    backgroundColor: '#ffd700',
    padding: '15px',
  },
};
