import AWS from 'aws-sdk';
import md5 from 'crypto-js/md5';
import Base64 from 'crypto-js/enc-base64';

let instance = null;
const getS3Instance = () => {
  if (instance === null) {
    instance = new AWS.S3();
  }

  return instance;
};

async function copyObject(oldKey, newKey) {
  const s3 = getS3Instance();

  return s3
    .copyObject({
      Bucket: AWS.awsConfig.bucketName,
      CopySource: encodeURIComponent(
        '/' + AWS.awsConfig.bucketName + '/' + oldKey,
      ),
      Key: newKey,
    })
    .promise();
}

async function deleteObject(key) {
  const s3 = getS3Instance();

  return s3
    .deleteObject({
      Bucket: AWS.awsConfig.bucketName,
      Key: key,
    })
    .promise();
}

async function exists(key) {
  try {
    await headObject(key);
    return true;
  } catch (err) {
    return false;
  }
}

async function getJSON(key) {
  return JSON.parse(new TextDecoder().decode((await getObject(key)).Body));
}

async function getObject(key) {
  const s3 = getS3Instance();

  return s3
    .getObject({
      Bucket: AWS.awsConfig.bucketName,
      Key: key,
      ResponseCacheControl: 'no-cache',
    })
    .promise();
}

async function headObject(key) {
  const s3 = getS3Instance();

  return s3
    .headObject({
      Bucket: AWS.awsConfig.bucketName,
      Key: key,
    })
    .promise();
}

async function invalidateCache() {
  const cf = new AWS.CloudFront();

  const data = await cf
    .createInvalidation({
      DistributionId: AWS.awsConfig.distributionId,
      InvalidationBatch: {
        CallerReference:
          'manage.cdn.knightsofvasteel.com ' + new Date().getTime(),
        Paths: {
          Quantity: 1,
          Items: ['/*'],
        },
      },
    })
    .promise();

  return cf
    .waitFor('invalidationCompleted', {
      DistributionId: AWS.awsConfig.distributionId,
      Id: data.Invalidation.Id,
    })
    .promise();
}

async function moveObject(oldKey, newKey) {
  await copyObject(oldKey, newKey);
  return deleteObject(oldKey);
}

async function putFile(key, file) {
  return new AWS.S3.ManagedUpload({
    params: {
      Body: file,
      Bucket: AWS.awsConfig.bucketName,
      Key: key,
    },
  }).promise();
}

async function putJSON(key, data) {
  return putObject(key, JSON.stringify(data));
}

async function putObject(key, data) {
  const s3 = getS3Instance();

  return s3
    .putObject({
      Body: data,
      Bucket: AWS.awsConfig.bucketName,
      ContentMD5: Base64.stringify(md5(data)),
      Key: key,
    })
    .promise();
}

export default {
  copyObject,
  deleteObject,
  exists,
  getJSON,
  getObject,
  headObject,
  invalidateCache,
  moveObject,
  putFile,
  putJSON,
  putObject,
};
