import React, { useRef, useState } from 'react';

import s3 from '../s3';
import { decode, genderMask, jobMask, raceMask } from '../NPCConsts';
import NPCMaskGenerator from './NPCMaskGenerator';
import S3Image from './S3Image';

export default function ({ packs, onDelete, onSave }) {
  const [originalId, setOriginalId] = useState(null);
  const [tempPack, setTempPack] = useState(null);
  const [tempImage, setTempImage] = useState(null);

  const portraitRef = useRef(null);
  const thumbnailRef = useRef(null);

  const filteredPacks = packs.filter(
    (pack) => pack.id !== 'com.knightsofvasteel.npcforhire',
  );

  let newVersionRequired = false;

  return (
    <>
      {tempPack === null && tempImage === null && (
        <>
          <button
            onClick={() => {
              setOriginalId(null);
              setTempPack({
                description: '',
                id: '',
                images: [],
                title: '',
                version: 0,
              });
            }}
            type="button"
          >
            + Add Pack
          </button>
          <div>
            <div>Count: {filteredPacks.length}</div>
            {filteredPacks
              .sort((a, b) => (a.title > b.title ? 1 : -1))
              .map((pack) => (
                <div
                  key={pack.id}
                  onClick={() => {
                    setOriginalId(pack.id);
                    setTempPack({
                      description: pack.description,
                      id: pack.id,
                      images: pack.images,
                      title: pack.title,
                      version: pack.version,
                    });
                  }}
                  style={styles.row}
                >
                  {pack.title} - {pack.id}
                </div>
              ))}
          </div>
        </>
      )}
      {tempPack !== null && tempImage === null && (
        <>
          <div>Version: {tempPack.version}</div>
          <input
            onChange={(e) =>
              setTempPack({
                description: tempPack.description,
                id: tempPack.id,
                images: tempPack.images,
                title: e.target.value,
                version: tempPack.version,
              })
            }
            placeholder="Title"
            style={styles.input}
            value={tempPack.title}
          />
          <input
            onChange={(e) =>
              setTempPack({
                description: tempPack.description,
                id: e.target.value,
                images: tempPack.images,
                title: tempPack.title,
                version: tempPack.version,
              })
            }
            placeholder="ID"
            style={styles.input}
            value={tempPack.id}
          />
          <textarea
            onChange={(e) =>
              setTempPack({
                description: e.target.value,
                id: tempPack.id,
                images: tempPack.images,
                title: tempPack.title,
                version: tempPack.version,
              })
            }
            placeholder="Description"
            rows="10"
            style={styles.textarea}
            value={tempPack.description}
          />
          <div>
            {tempPack.images.map((image, index) => (
              <div key={index} style={styles.imageContainer}>
                <S3Image
                  description={image.tag}
                  path={`public/npc-for-hire/${tempPack.id}/${index}.jpg`}
                  title={decode(image.tag)}
                />
                <button
                  onClick={() =>
                    setTempImage({
                      index: index,
                      tag: image.tag,
                    })
                  }
                  type="button"
                >
                  Edit Image
                </button>
                {index + 1 === tempPack.images.length && (
                  <button
                    onClick={async () => {
                      if (window.confirm('Delete?')) {
                        // delete portrait
                        await s3.deleteObject(
                          `protected/npc-for-hire/${tempPack.id}/${index}.jpg`,
                        );

                        // delete thumbnail
                        await s3.deleteObject(
                          `public/npc-for-hire/${tempPack.id}/${index}.jpg`,
                        );

                        let newPack = {
                          description: tempPack.description,
                          id: tempPack.id,
                          images: tempPack.images.slice(0, -1),
                          title: tempPack.title,
                          version: tempPack.version + 1,
                        };

                        onSave(
                          Object.assign({}, newPack, {
                            originalId: originalId || newPack.id,
                          }),
                        );
                        setTempPack(newPack);
                      }
                    }}
                    type="button"
                  >
                    Delete Image
                  </button>
                )}
              </div>
            ))}
            <button
              onClick={() =>
                setTempImage({
                  index: tempPack.images.length,
                  tag: '',
                })
              }
              type="button"
            >
              + Add Image
            </button>
          </div>
          <button
            onClick={() => {
              tempPack.title = tempPack.title.trim();
              if (tempPack.title === '') {
                window.error('Title is required.');
                return;
              }

              tempPack.id = tempPack.id.trim();
              if (tempPack.id === '') {
                window.error('ID is required.');
                return;
              }

              tempPack.description = tempPack.description.trim();
              if (tempPack.description === '') {
                window.error('Description is required.');
                return;
              }

              onSave(
                Object.assign({}, tempPack, {
                  originalId: originalId || tempPack.id,
                }),
              );

              setTempPack(null);
            }}
            type="button"
          >
            Save
          </button>
          <button onClick={() => setTempPack(null)} type="button">
            Cancel
          </button>
          {tempPack.index !== null && (
            <button
              onClick={async () => {
                if (window.confirm('Delete?')) {
                  // delete images
                  for (
                    let index = 0, l = tempPack.images.length;
                    index < l;
                    index++
                  ) {
                    await s3.deleteObject(
                      `protected/npc-for-hire/${tempPack.id}/${index}.jpg`,
                    );
                    await s3.deleteObject(
                      `public/npc-for-hire/${tempPack.id}/${index}.jpg`,
                    );
                  }

                  onDelete(tempPack.id);
                  setTempPack(null);
                }
              }}
              type="button"
            >
              Delete
            </button>
          )}
        </>
      )}
      {tempImage !== null && (
        <>
          <div>Pack: {tempPack.title}</div>
          <NPCMaskGenerator
            label="Tag: "
            mask={tempImage.tag}
            onGenerate={(mask) =>
              setTempImage({
                index: tempImage.index,
                tag: mask,
              })
            }
          />
          <div>
            Portrait:{' '}
            <input
              onChange={() => (newVersionRequired = true)}
              ref={portraitRef}
              type="file"
            />
          </div>
          <S3Image
            path={`protected/npc-for-hire/${tempPack.id}/${tempImage.index}.jpg`}
          />
          <div>
            Thumbnail:{' '}
            <input
              onChange={() => (newVersionRequired = true)}
              ref={thumbnailRef}
              type="file"
            />
          </div>
          <S3Image
            path={`public/npc-for-hire/${tempPack.id}/${tempImage.index}.jpg`}
          />
          <div>
            <button
              onClick={async () => {
                if (
                  !genderMask.hasAny(tempImage.tag) ||
                  !jobMask.hasAny(tempImage.tag) ||
                  !raceMask.hasAny(tempImage.tag)
                ) {
                  window.error('Must choose at least 1 gender, job, and race.');
                  return;
                }

                let portraitKey = `protected/npc-for-hire/${tempPack.id}/${tempImage.index}.jpg`;
                let portraitExists = await s3.exists(portraitKey);

                let portraitFile = portraitRef.current.files[0];
                let portraitFileIsValid =
                  portraitFile &&
                  portraitFile.type === 'image/jpeg' &&
                  portraitFile.size !== 0;

                if (portraitFile && !portraitFileIsValid) {
                  window.error('Portrait must be a jpeg.');
                  return;
                }

                if (!portraitExists && !portraitFile) {
                  window.error('Portrait is required.');
                  return;
                }

                let thumbnailKey = `public/npc-for-hire/${tempPack.id}/${tempImage.index}.jpg`;
                let thumbnailExists = await s3.exists(thumbnailKey);

                let thumbnailFile = thumbnailRef.current.files[0];
                let thumbnailFileIsValid =
                  thumbnailFile &&
                  thumbnailFile.type === 'image/jpeg' &&
                  thumbnailFile.size !== 0;

                if (thumbnailFile && !thumbnailFileIsValid) {
                  window.error('Thumbnail must be a jpeg.');
                  return;
                }

                if (!thumbnailExists && !thumbnailFile) {
                  window.error('Thumbnail is required.');
                  return;
                }

                if (portraitFile) {
                  await s3.putFile(portraitKey, portraitFile);
                }

                if (thumbnailFile) {
                  await s3.putFile(thumbnailKey, thumbnailFile);
                }

                let newPack = {
                  description: tempPack.description,
                  id: tempPack.id,
                  images: tempPack.images.slice(),
                  title: tempPack.title,
                  version: tempPack.version + (newVersionRequired ? 1 : 0),
                };

                newVersionRequired = false;

                if (tempImage.index === newPack.images.length) {
                  newPack.images.push({ tag: tempImage.tag });
                } else {
                  newPack.images[tempImage.index] = { tag: tempImage.tag };
                }

                onSave(
                  Object.assign({}, newPack, {
                    originalId: originalId || newPack.id,
                  }),
                );
                setTempPack(newPack);
                setTempImage(null);
              }}
              type="button"
            >
              Save
            </button>
            <button onClick={() => setTempImage(null)} type="button">
              Cancel
            </button>
          </div>
        </>
      )}
    </>
  );
}

const styles = {
  imageContainer: {
    display: 'inline-block',
    margin: '15px',
  },
  input: {
    display: 'block',
    width: '300px',
  },
  radio: {
    margin: '0 5px',
  },
  row: {
    borderBottom: '1px solid #000',
    cursor: 'pointer',
    padding: '10px',
  },
  search: {
    display: 'block',
  },
  textarea: {
    width: '100%',
  },
  token: {
    backgroundColor: '#eee',
    margin: '0 5px',
    padding: '0 5px',
  },
};
