import React from 'react';

export default function ({ onSelect, selected }) {
  const types = {
    descriptions: 'descriptions',
    mechanics: 'mechanics',
    names: 'names',
    packs: 'packs',
    promo: 'promo',
    statics: 'statics',
  };

  return (
    <div style={styles.container}>
      <span>Content:</span>
      <div
        onClick={() => onSelect(types.descriptions)}
        style={Object.assign(
          {},
          styles.button,
          selected === types.descriptions && styles.selected,
        )}
      >
        Descriptions
      </div>
      <div
        onClick={() => onSelect(types.mechanics)}
        style={Object.assign(
          {},
          styles.button,
          selected === types.mechanics && styles.selected,
        )}
      >
        Mechanics
      </div>
      <div
        onClick={() => onSelect(types.names)}
        style={Object.assign(
          {},
          styles.button,
          selected === types.names && styles.selected,
        )}
      >
        Names
      </div>
      <div
        onClick={() => onSelect(types.packs)}
        style={Object.assign(
          {},
          styles.button,
          selected === types.packs && styles.selected,
        )}
      >
        Packs
      </div>
      <div
        onClick={() => onSelect(types.promo)}
        style={Object.assign(
          {},
          styles.button,
          selected === types.promo && styles.selected,
        )}
      >
        Promo
      </div>
      <div
        onClick={() => onSelect(types.statics)}
        style={Object.assign(
          {},
          styles.button,
          selected === types.statics && styles.selected,
        )}
      >
        Statics
      </div>
    </div>
  );
}

const styles = {
  button: {
    color: 'blue',
    cursor: 'pointer',
    display: 'inline-block',
    padding: '5px 10px',
  },
  container: {},
  selected: {
    textDecoration: 'underline',
  },
};
