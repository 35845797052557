import React, { useState } from 'react';

import BitArray from '../BitArray';
import { damageTypes, itemTypes, rarities } from '../itemConsts';
import ItemMaskGenerator from './ItemMaskGenerator';

const tokens = [
  'name',
  'item',
  'using',
  'has',
  'is',
  'It',
  'it',
  'its',
  'This',
  'this',
  'was',
];
function doesValueHaveUnescapedTokens(value) {
  for (let i = 0; i < tokens.length; i++) {
    const regex = new RegExp(`(^|\\s)${tokens[i]}\\b`);
    if (regex.test(value)) {
      return true;
    }
  }

  return false;
}

function preview(value) {
  value = value.split('{name}').join('Kuai & Yun');
  value = value.split('{item}').join('nunchucks');
  value = value.split('{using}').join('wielding');

  const pluralMapping = {
    has: 'have',
    is: 'are',
    It: 'They',
    it: 'they',
    its: 'their',
    This: 'These',
    this: 'these',
    was: 'were',
  };

  for (const singular in pluralMapping) {
    const find = '{' + singular + '}';
    const replace = pluralMapping[singular];
    value = value.split(find).join(replace);
  }

  value = value.replace(/\{([^|}]*)\|([^}]*)\}/g, '$2');

  return value;
}

export default function ({ descriptions, onAdd, onDelete, onEdit }) {
  const [damageTypeFilter, setDamageTypeFilter] = useState(new BitArray(''));
  const [itemTypeFilter, setItemTypeFilter] = useState(itemTypes.mask);
  const [packSearch, setPackSearch] = useState('');
  const [rarityFilter, setRarityFilter] = useState(rarities.mask);

  const [search, setSearch] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [tempDescription, setTempDescription] = useState(null);

  const filteredDescriptions = descriptions
    .filter(
      ({ damageType, itemType, rarity }) =>
        (damageTypeFilter.equals('0')
          ? true
          : damageTypeFilter.hasAny(damageType)) &&
        itemTypeFilter.hasAny(itemType) &&
        rarityFilter.hasAny(rarity),
    )
    .filter(
      ({ append, prepend, value }) =>
        (append || '').indexOf(search) !== -1 ||
        (prepend || '').indexOf(search) !== -1 ||
        value.indexOf(search) !== -1,
    )
    .filter(({ pack }) =>
      packSearch === 'default'
        ? pack === undefined
        : (pack || '').indexOf(packSearch) !== -1,
    );

  return (
    <>
      {tempDescription === null && (
        <>
          <ItemMaskGenerator
            mask={damageTypeFilter}
            onGenerate={setDamageTypeFilter}
            type="damageType"
          />
          <ItemMaskGenerator
            mask={itemTypeFilter}
            onGenerate={setItemTypeFilter}
            type="itemType"
          />
          <ItemMaskGenerator
            mask={rarityFilter}
            onGenerate={setRarityFilter}
            type="rarity"
          />
          <input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            style={styles.search}
            type="text"
            value={search}
          />
          <input
            onChange={(e) => setPackSearch(e.target.value)}
            placeholder="Pack Search..."
            style={styles.search}
            title="type 'default' for no pack"
            type="text"
            value={packSearch}
          />
          <button
            onClick={() =>
              setTempDescription({
                // append (optional)
                // damageType (optional)
                index: null,
                itemType: '',
                // prepend (optional)
                rarity: '',
                value: '',
              })
            }
            type="button"
          >
            + Add Description
          </button>
          <div>
            <div>Count: {filteredDescriptions.length}</div>
            {filteredDescriptions.map((description) => (
              <div
                key={description.index}
                onClick={() => {
                  setTempDescription({ ...description });
                  setShowWarning(
                    doesValueHaveUnescapedTokens(description.value),
                  );
                }}
                style={styles.row}
              >
                <span title={damageTypes.decode(description.damageType || 0)}>
                  {description.damageType || 0}
                </span>
                :
                <span title={itemTypes.decode(description.itemType)}>
                  {description.itemType}
                </span>
                :
                <span title={rarities.decode(description.rarity)}>
                  {description.rarity}
                </span>{' '}
                - {description.value}
              </div>
            ))}
          </div>
        </>
      )}
      {tempDescription !== null && (
        <>
          <ItemMaskGenerator
            mask={tempDescription.damageType}
            onGenerate={(damageType) => {
              const tag = damageType.toString();
              if (tag) {
                setTempDescription({
                  ...tempDescription,
                  damageType: tag,
                });
              } else {
                // remove damageType from the description object if it's not set
                const { damageType, ...nextDescription } = tempDescription;
                setTempDescription(nextDescription);
              }
            }}
            type="damageType"
          />
          <ItemMaskGenerator
            mask={tempDescription.itemType}
            onGenerate={(itemType) =>
              setTempDescription({
                ...tempDescription,
                itemType: itemType.toString(),
              })
            }
            type="itemType"
          />
          <ItemMaskGenerator
            mask={tempDescription.rarity}
            onGenerate={(rarity) =>
              setTempDescription({
                ...tempDescription,
                rarity: rarity.toString(),
              })
            }
            type="rarity"
          />
          <input
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === '') {
                // remove pack from the object if its not set
                const { pack, ...nextDescription } = tempDescription;
                setTempDescription(nextDescription);
              } else {
                setTempDescription({ ...tempDescription, pack: value });
              }
            }}
            placeholder="Pack ID (optional)"
            style={styles.input}
            value={tempDescription.pack || ''}
          />
          <div>
            Available tokens:
            {tokens.map((token) => (
              <span key={token} style={styles.token}>{`{${token}}`}</span>
            ))}
            <span style={styles.token}>{'{singular|plural}'}</span>
          </div>
          <textarea
            onChange={(e) => {
              setTempDescription({ ...tempDescription, value: e.target.value });
              setShowWarning(doesValueHaveUnescapedTokens(e.target.value));
            }}
            placeholder="Description (required)"
            required
            rows="10"
            style={styles.textarea}
            value={tempDescription.value}
          />
          {showWarning && (
            <div style={styles.warning}>
              The description contains a token that is not wrapped in curly
              braces.
            </div>
          )}
          <div style={styles.preview}>{preview(tempDescription.value)}</div>
          <input
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === '') {
                // remove prepend from the object if its not set
                const { prepend, ...nextDescription } = tempDescription;
                setTempDescription(nextDescription);
              } else {
                setTempDescription({ ...tempDescription, prepend: value });
              }
            }}
            placeholder="Prepend (optional)"
            style={styles.input}
            value={tempDescription.prepend || ''}
          />
          <input
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === '') {
                // remove append from the object if its not set
                const { append, ...nextDescription } = tempDescription;
                setTempDescription(nextDescription);
              } else {
                setTempDescription({ ...tempDescription, append: value });
              }
            }}
            placeholder="Append (optional)"
            style={styles.input}
            value={tempDescription.append || ''}
          />
          <div style={styles.preview}>{`${
            tempDescription.prepend || ''
          } Nunchucks ${tempDescription.append || ''}`}</div>
          <button
            onClick={() => {
              if (
                !itemTypes.mask.hasAny(tempDescription.itemType) ||
                !rarities.mask.hasAny(tempDescription.rarity) ||
                new BitArray(tempDescription.rarity).toFlags().length > 1
              ) {
                window.error(
                  'Must choose at least 1 item type, and a single rarity.',
                );
                return;
              }

              tempDescription.value = tempDescription.value.trim();
              if (tempDescription.value === '') {
                window.error('Description cannot be blank.');
                return;
              }

              const index = tempDescription.index;
              delete tempDescription.index;

              if (index === null) {
                onAdd(tempDescription);
              } else {
                onEdit(tempDescription, index);
              }

              setTempDescription(null);
            }}
            type="button"
          >
            Save
          </button>
          <button onClick={() => setTempDescription(null)} type="button">
            Cancel
          </button>
          {tempDescription.index !== null && (
            <button
              onClick={() => {
                if (window.confirm('Delete?')) {
                  onDelete(tempDescription.index);
                  setTempDescription(null);
                }
              }}
              type="button"
            >
              Delete
            </button>
          )}
        </>
      )}
    </>
  );
}

const styles = {
  input: {
    display: 'block',
    width: '700px',
  },
  preview: {
    backgroundColor: '#eee',
    padding: '15px',
    whiteSpace: 'pre-wrap',
  },
  row: {
    borderBottom: '1px solid #000',
    cursor: 'pointer',
    padding: '10px',
  },
  search: {
    display: 'block',
  },
  textarea: {
    width: '100%',
  },
  token: {
    backgroundColor: '#eee',
    margin: '0 5px',
    padding: '0 5px',
  },
  warning: {
    backgroundColor: '#ffd700',
    padding: '15px',
  },
};
