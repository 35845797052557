import React, { useEffect, useState } from 'react';

import BitArray from '../BitArray';
import { damageTypes, itemTypes, rarities } from '../itemConsts';
import CheckboxList from './CheckboxList';

export default function ({ mask, onGenerate, type }) {
  const [open, setOpen] = useState(false);

  mask = new BitArray(mask);

  const label =
    type === 'damageType'
      ? 'Damage Type: '
      : type === 'itemType'
      ? 'Item Type: '
      : 'Rarity: ';

  const list =
    type === 'damageType'
      ? damageTypes
      : type === 'itemType'
      ? [...new Set(Object.values(itemTypes.lookup.map))]
      : rarities;

  if (type === 'itemType') {
    list.decode = itemTypes.decode;
  }

  const [items, setItems] = useState(
    list.map((item) => ({ ...item, checked: mask.hasFlag(item.flag) })),
  );

  useEffect(() => {
    let bits = new BitArray();

    for (let item of items) {
      if (item.checked) {
        bits = bits.or(item.flag);
      }
    }

    if (!bits.equals(mask)) {
      onGenerate(bits);
    }
  }, [items, mask, onGenerate]);

  return (
    <div>
      <span>{label}</span>
      <span
        onClick={() => setOpen(!open)}
        style={Object.assign({}, styles.button, open && styles.selected)}
        title={list.decode(mask)}
      >
        {mask.toString() || 'None'}
      </span>
      {open && (
        <>
          <button
            onClick={() =>
              setItems(
                items.map((item) => {
                  item.checked = !item.checked;
                  return item;
                }),
              )
            }
            type="button"
          >
            Toggle All
          </button>
          <CheckboxList
            items={
              type === 'itemType'
                ? items.sort((a, b) => (a.label > b.label ? 1 : -1))
                : items
            }
            onChange={(flag) =>
              setItems(
                items.map((item) => {
                  if (item.flag === flag) {
                    item.checked = !item.checked;
                  }

                  return item;
                }),
              )
            }
          />
        </>
      )}
    </div>
  );
}

const styles = {
  button: {
    color: 'blue',
    cursor: 'pointer',
    display: 'inline-block',
    padding: '5px 10px',
  },
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '5px 0',
  },
  container: {},
  selected: {
    textDecoration: 'underline',
  },
};
