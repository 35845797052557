import BitArray from './BitArray';

/**
 * Creates a function that will decode a given mask
 *
 * @return (mask: BitArray) => string[]
 */
const createDecode = (array) => {
  return (mask) => {
    mask = new BitArray(mask);

    let matches = [];

    for (let item of array) {
      if (mask.hasFlag(item.flag)) {
        matches.push(item.label);
      }
    }

    return matches.join(', ');
  };
};

/**
 * Creates a function that can lookup an item by flag or label
 *
 * Each item will have the following bits:
 *  flag: the flag unique to this item: 0100 (Melee)
 *  mask: the flags that are considered this item: 0111 (Melee, Axe, Sword)
 *  tag: the flags this item is considered: 1100 (Weapon, Melee)
 *
 * @param array array The array to create the lookup function for
 * @return (flagOrLabel: string) => item
 */
const createLookup = (array) => {
  const reduceArray = (array, parent) =>
    array.reduce((map, item) => {
      item.mask = new BitArray(item.flag);
      item.tag = new BitArray(item.flag);

      if (parent) {
        item.parent = parent;

        let p = parent;
        while (p) {
          p.mask = p.mask.or(item.flag);
          p = p.parent;
        }

        item.tag = item.tag.or(parent.tag);

        if (parent.using && !item.using) {
          item.using = parent.using;
        }
      }

      map = { ...map, [item.label.toLowerCase()]: item, [item.flag]: item };

      if (item.subtypes) {
        map = { ...map, ...reduceArray(item.subtypes, item) };
      }

      return map;
    }, {});

  const map = reduceArray(array);
  const fn = (flagOrLabel) => map[flagOrLabel.toLowerCase()];
  fn.map = map;

  return fn;
};

const createMask = (array) =>
  array.reduce((mask, { flag }) => mask.or(flag), new BitArray(0));

const damageTypes = [
  { flag: '1', label: 'Acid' },
  { flag: '10', label: 'Cold' },
  { flag: '100', label: 'Fire' },
  { flag: '1000', label: 'Force' },
  { flag: '10000', label: 'Lightning' },
  { flag: '100000', label: 'Necrotic' },
  { flag: '1000000', label: 'Poison' },
  { flag: '10000000', label: 'Psychic' },
  { flag: '100000000', label: 'Radiant' },
  { flag: '1000000000', label: 'Thunder' },
];
damageTypes.decode = createDecode(damageTypes);
damageTypes.lookup = createLookup(damageTypes);
damageTypes.mask = createMask(damageTypes);

const itemTypes = [
  {
    flag: '1',
    label: 'Arcane',
    subtypes: [
      { flag: '10', label: 'Scroll', using: 'reading' },
      { flag: '100', label: 'Staff' },
      {
        flag: '1000',
        label: 'Trinket',
        subtypes: [
          { flag: '10000', label: 'Amulet' },
          { flag: '100000', label: 'Ring' },
        ],
        using: 'wearing',
      },
      { flag: '1000000', label: 'Wand' },
    ],
    using: 'holding',
  },
  {
    flag: '10000000',
    label: 'Armor',
    subtypes: [
      { flag: '100000000', label: 'Boots', plural: true },
      { flag: '1000000000', label: 'Bracers', plural: true },
      {
        flag: '10000000000',
        label: 'Chest',
        subtypes: [
          {
            flag: '100000000000',
            label: 'Heavy Armor',
            subtypes: [
              { flag: '1000000000000', label: 'Chain Mail' },
              { flag: '10000000000000', label: 'Plate Armor' },
            ],
          },
          {
            flag: '100000000000000',
            label: 'Light Armor',
            subtypes: [
              { flag: '1000000000000000', label: 'Leather Armor' },
              { flag: '10000000000000000', label: 'Padded Armor' },
              { flag: '100000000000000000', label: 'Studded Armor' },
            ],
          },
          {
            flag: '1000000000000000000',
            label: 'Medium Armor',
            subtypes: [
              { flag: '10000000000000000000', label: 'Breastplate' },
              { flag: '100000000000000000000', label: 'Hide Armor' },
              { flag: '1000000000000000000000', label: 'Scale Mail' },
            ],
          },
        ],
      },
      { flag: '10000000000000000000000', label: 'Cloak' },
      { flag: '100000000000000000000000', label: 'Gauntlets', plural: true },
      { flag: '1000000000000000000000000', label: 'Helm' },
      {
        flag: '10000000000000000000000000',
        label: 'Shield',
        using: 'wielding',
      },
    ],
    using: 'wearing',
  },
  { flag: '100000000000000000000000000', label: 'Potion', using: 'drinking' },
  {
    flag: '1000000000000000000000000000',
    label: 'Weapon',
    subtypes: [
      {
        flag: '10000000000000000000000000000',
        label: 'Ammunition',
        subtypes: [
          {
            flag: '100000000000000000000000000000',
            label: 'Arrows',
            plural: true,
          },
          {
            flag: '1000000000000000000000000000000',
            label: 'Bolts',
            plural: true,
          },
        ],
        using: 'firing',
      },
      {
        flag: '10000000000000000000000000000000',
        label: 'Melee',
        subtypes: [
          { flag: '100000000000000000000000000000000', label: 'Axe' },
          { flag: '1000000000000000000000000000000000', label: 'Club' },
          { flag: '10000000000000000000000000000000000', label: 'Dagger' },
          { flag: '100000000000000000000000000000000000', label: 'Halberd' },
          { flag: '1000000000000000000000000000000000000', label: 'Hammer' },
          { flag: '10000000000000000000000000000000000000', label: 'Mace' },
          {
            flag: '100000000000000000000000000000000000000',
            label: 'Quarterstaff',
          },
          { flag: '1000000000000000000000000000000000000000', label: 'Scythe' },
          { flag: '10000000000000000000000000000000000000000', label: 'Spear' },
          {
            flag: '100000000000000000000000000000000000000000',
            label: 'Sword',
          },
          {
            flag: '1000000000000000000000000000000000000000000',
            label: 'Whip',
          },
        ],
      },
      {
        flag: '10000000000000000000000000000000000000000000',
        label: 'Ranged',
        subtypes: [
          {
            flag: '100000000000000000000000000000000000000000000',
            label: 'Bow',
          },
          {
            flag: '1000000000000000000000000000000000000000000000',
            label: 'Crossbow',
          },
          {
            flag: '10000000000000000000000000000000000000000000000',
            label: 'Javelins',
            plural: true,
          },
        ],
      },
    ],
    using: 'wielding',
  },
  {
    flag: '100000000000000000000000000000000000000000000000',
    label: 'Wondrous',
  },
];
itemTypes.lookup = createLookup(itemTypes);
itemTypes.decode = createDecode([
  ...new Set(Object.values(itemTypes.lookup.map)),
]);
itemTypes.mask = createMask([...new Set(Object.values(itemTypes.lookup.map))]);

const rarities = [
  // { flag: '1', label: 'Common' }, // we aren't using this at the moment
  { flag: '10', label: 'Uncommon' },
  { flag: '100', label: 'Rare' },
  { flag: '1000', label: 'Very Rare' },
  { flag: '10000', label: 'Legendary' },
];
rarities.decode = createDecode(rarities);
rarities.lookup = createLookup(rarities);
rarities.mask = createMask(rarities);

export { damageTypes, itemTypes, rarities };
