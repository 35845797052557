import Amplify from 'aws-amplify';
import AWS from 'aws-sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastProvider } from 'react-toast-notifications';

import awsConfig from './aws-config';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

Amplify.configure({
  Auth: {
    identityPoolId: awsConfig.cognitoIdentityPoolId,
    region: awsConfig.region,
    userPoolId: awsConfig.cognitoUserPoolId,
    userPoolWebClientId: awsConfig.appClientId,
  },
});

AWS.config.region = awsConfig.region;
AWS.awsConfig = awsConfig;

ReactDOM.render(
  <ToastProvider autoDismiss>
    <App />
  </ToastProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
