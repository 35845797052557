import React, { useState } from 'react';

import BitArray from '../BitArray';
import { damageTypes, itemTypes, rarities } from '../itemConsts';
import ItemMaskGenerator from './ItemMaskGenerator';

const tokens = [
  'name',
  'item',
  'using',
  'has',
  'is',
  'It',
  'it',
  'its',
  'This',
  'this',
  'was',
];
function doesValueHaveUnescapedTokens(value) {
  for (let i = 0; i < tokens.length; i++) {
    const regex = new RegExp(`(^|\\s)${tokens[i]}\\b`);
    if (regex.test(value)) {
      return true;
    }
  }

  return false;
}

function preview(value) {
  value = value.split('{name}').join('Kuai & Yun');
  value = value.split('{item}').join('nunchucks');
  value = value.split('{using}').join('wielding');

  const pluralMapping = {
    has: 'have',
    is: 'are',
    It: 'They',
    it: 'they',
    its: 'their',
    This: 'These',
    this: 'these',
    was: 'were',
  };

  for (const singular in pluralMapping) {
    const find = '{' + singular + '}';
    const replace = pluralMapping[singular];
    value = value.split(find).join(replace);
  }

  value = value.replace(/\{([^|}]*)\|([^}]*)\}/g, '$2');

  return value;
}

export default function ({ mechanics, onAdd, onDelete, onEdit }) {
  const [damageTypeFilter, setDamageTypeFilter] = useState(new BitArray(''));
  const [itemTypeFilter, setItemTypeFilter] = useState(itemTypes.mask);
  const [packSearch, setPackSearch] = useState('');
  const [rarityFilter, setRarityFilter] = useState(rarities.mask);

  const [search, setSearch] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [tempMechanic, setTempMechanic] = useState(null);

  const filteredMechanics = mechanics
    .filter(
      ({ damageType, itemType, rarity }) =>
        (damageTypeFilter.equals('0')
          ? true
          : damageTypeFilter.hasAny(damageType)) &&
        itemTypeFilter.hasAny(itemType) &&
        rarityFilter.hasAny(rarity),
    )
    .filter(
      ({ append, prepend, requires, value }) =>
        (append || '').indexOf(search) !== -1 ||
        (prepend || '').indexOf(search) !== -1 ||
        (requires || '').indexOf(search) !== -1 ||
        value.indexOf(search) !== -1,
    )
    .filter(({ pack }) =>
      packSearch === 'default'
        ? pack === undefined
        : (pack || '').indexOf(packSearch) !== -1,
    );

  return (
    <>
      {tempMechanic === null && (
        <>
          <ItemMaskGenerator
            mask={damageTypeFilter}
            onGenerate={setDamageTypeFilter}
            type="damageType"
          />
          <ItemMaskGenerator
            mask={itemTypeFilter}
            onGenerate={setItemTypeFilter}
            type="itemType"
          />
          <ItemMaskGenerator
            mask={rarityFilter}
            onGenerate={setRarityFilter}
            type="rarity"
          />
          <input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            style={styles.search}
            type="text"
            value={search}
          />
          <input
            onChange={(e) => setPackSearch(e.target.value)}
            placeholder="Pack Search..."
            style={styles.search}
            title="type 'default' for no pack"
            type="text"
            value={packSearch}
          />
          <button
            onClick={() =>
              setTempMechanic({
                // append (optional)
                // damageType (optional)
                index: null,
                itemType: '',
                // prepend (optional)
                rarity: '',
                // requires (optional)
                value: '',
              })
            }
            type="button"
          >
            + Add Mechanic
          </button>
          <div>
            <div>Count: {filteredMechanics.length}</div>
            {filteredMechanics.map((mechanic) => (
              <div
                key={mechanic.index}
                onClick={() => {
                  setTempMechanic({ ...mechanic });
                  setShowWarning(doesValueHaveUnescapedTokens(mechanic.value));
                }}
                style={styles.row}
              >
                <span title={damageTypes.decode(mechanic.damageType || 0)}>
                  {mechanic.damageType || 0}
                </span>
                :
                <span title={itemTypes.decode(mechanic.itemType)}>
                  {mechanic.itemType}
                </span>
                :
                <span title={rarities.decode(mechanic.rarity)}>
                  {mechanic.rarity}
                </span>{' '}
                - {mechanic.value}
              </div>
            ))}
          </div>
        </>
      )}
      {tempMechanic !== null && (
        <>
          <ItemMaskGenerator
            mask={tempMechanic.damageType}
            onGenerate={(damageType) => {
              const tag = damageType.toString();
              if (tag) {
                setTempMechanic({
                  ...tempMechanic,
                  damageType: tag,
                });
              } else {
                // remove damageType from the mechanic object if it's not set
                const { damageType, ...nextMechanic } = tempMechanic;
                setTempMechanic(nextMechanic);
              }
            }}
            type="damageType"
          />
          <ItemMaskGenerator
            mask={tempMechanic.itemType}
            onGenerate={(itemType) =>
              setTempMechanic({
                ...tempMechanic,
                itemType: itemType.toString(),
              })
            }
            type="itemType"
          />
          <ItemMaskGenerator
            mask={tempMechanic.rarity}
            onGenerate={(rarity) =>
              setTempMechanic({
                ...tempMechanic,
                rarity: rarity.toString(),
              })
            }
            type="rarity"
          />
          <input
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === '') {
                // remove pack from the object if its not set
                const { pack, ...nextMechanic } = tempMechanic;
                setTempMechanic(nextMechanic);
              } else {
                setTempMechanic({ ...tempMechanic, pack: value });
              }
            }}
            placeholder="Pack ID (optional)"
            style={styles.input}
            value={tempMechanic.pack || ''}
          />
          <div>
            Available tokens:
            {tokens.map((token) => (
              <span key={token} style={styles.token}>{`{${token}}`}</span>
            ))}
            <span style={styles.token}>{'{singular|plural}'}</span>
          </div>
          <textarea
            onChange={(e) => {
              setTempMechanic({ ...tempMechanic, value: e.target.value });
              setShowWarning(doesValueHaveUnescapedTokens(e.target.value));
            }}
            placeholder="Mechanic (required)"
            required
            rows="10"
            style={styles.textarea}
            value={tempMechanic.value}
          />
          {showWarning && (
            <div style={styles.warning}>
              The mechanic contains a token that is not wrapped in curly braces.
            </div>
          )}
          <div style={styles.preview}>{preview(tempMechanic.value)}</div>
          <input
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === '') {
                // remove prepend from the object if its not set
                const { prepend, ...nextMechanic } = tempMechanic;
                setTempMechanic(nextMechanic);
              } else {
                setTempMechanic({ ...tempMechanic, prepend: value });
              }
            }}
            placeholder="Prepend (optional)"
            style={styles.input}
            value={tempMechanic.prepend || ''}
          />
          <input
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === '') {
                // remove append from the object if its not set
                const { append, ...nextMechanic } = tempMechanic;
                setTempMechanic(nextMechanic);
              } else {
                setTempMechanic({ ...tempMechanic, append: value });
              }
            }}
            placeholder="Append (optional)"
            style={styles.input}
            value={tempMechanic.append || ''}
          />
          <div style={styles.preview}>{`${
            tempMechanic.prepend || ''
          } Nunchucks ${tempMechanic.append || ''}`}</div>
          <input
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === '') {
                // remove requires from the object if its not set
                const { requires, ...nextMechanic } = tempMechanic;
                setTempMechanic(nextMechanic);
              } else {
                setTempMechanic({ ...tempMechanic, requires: value });
              }
            }}
            placeholder="Requires (optional) i.e. Attunement, Spellcaster"
            style={styles.input}
            value={tempMechanic.requires || ''}
          />
          <div>
            <label style={styles.label}>
              <span>Bonus Mechanic</span>
              <input
                checked={tempMechanic.bonus}
                onChange={() => {
                  if (tempMechanic.bonus) {
                    // remove bonus from the object if its not set
                    const { bonus, ...nextMechanic } = tempMechanic;
                    setTempMechanic(nextMechanic);
                  } else {
                    setTempMechanic({ ...tempMechanic, bonus: true });
                  }
                }}
                type="checkbox"
              />
            </label>
          </div>
          <button
            onClick={() => {
              if (
                !itemTypes.mask.hasAny(tempMechanic.itemType) ||
                !rarities.mask.hasAny(tempMechanic.rarity) ||
                new BitArray(tempMechanic.rarity).toFlags().length > 1
              ) {
                window.error(
                  'Must choose at least 1 item type, and a single rarity.',
                );
                return;
              }

              tempMechanic.value = tempMechanic.value.trim();
              if (tempMechanic.value === '') {
                window.error('Mechanic cannot be blank.');
                return;
              }

              const index = tempMechanic.index;
              delete tempMechanic.index;

              if (index === null) {
                onAdd(tempMechanic);
              } else {
                onEdit(tempMechanic, index);
              }

              setTempMechanic(null);
            }}
            type="button"
          >
            Save
          </button>
          <button onClick={() => setTempMechanic(null)} type="button">
            Cancel
          </button>
          {tempMechanic.index !== null && (
            <button
              onClick={() => {
                if (window.confirm('Delete?')) {
                  onDelete(tempMechanic.index);
                  setTempMechanic(null);
                }
              }}
              type="button"
            >
              Delete
            </button>
          )}
        </>
      )}
    </>
  );
}

const styles = {
  input: {
    display: 'block',
    width: '700px',
  },
  preview: {
    backgroundColor: '#eee',
    padding: '15px',
    whiteSpace: 'pre-wrap',
  },
  row: {
    borderBottom: '1px solid #000',
    cursor: 'pointer',
    padding: '10px',
  },
  search: {
    display: 'block',
  },
  textarea: {
    width: '100%',
  },
  token: {
    backgroundColor: '#eee',
    margin: '0 5px',
    padding: '0 5px',
  },
  warning: {
    backgroundColor: '#ffd700',
    padding: '15px',
  },
};
