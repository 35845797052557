import React, { useEffect, useState } from 'react';

import BitArray from '../BitArray';
import * as NPCConsts from '../NPCConsts';
import CheckboxList from './CheckboxList';

export default function ({ mask, label, onGenerate }) {
  const [open, setOpen] = useState(false);

  let bits = new BitArray(mask);

  const [genders, setGenders] = useState(
    NPCConsts.genders.map((gender) =>
      Object.assign({}, gender, { checked: bits.hasFlag(gender.flag) }),
    ),
  );

  const [jobs, setJobs] = useState(
    NPCConsts.jobs.map((job) =>
      Object.assign({}, job, { checked: bits.hasFlag(job.flag) }),
    ),
  );

  const [races, setRaces] = useState(
    NPCConsts.races.map((race) =>
      Object.assign({}, race, { checked: bits.hasFlag(race.flag) }),
    ),
  );

  useEffect(() => {
    let bits = new BitArray();

    for (let gender of genders) {
      if (gender.checked) {
        bits = bits.or(gender.flag);
      }
    }

    for (let job of jobs) {
      if (job.checked) {
        bits = bits.or(job.flag);
      }
    }

    for (let race of races) {
      if (race.checked) {
        bits = bits.or(race.flag);
      }
    }

    bits = bits.toString();
    if (bits !== mask) {
      onGenerate(bits);
    }
  }, [genders, jobs, mask, onGenerate, races]);

  return (
    <div>
      <span>{label}</span>
      <span
        onClick={() => setOpen(!open)}
        style={Object.assign({}, styles.button, open && styles.selected)}
        title={NPCConsts.decode(mask)}
      >
        {mask || 'None'}
      </span>
      {open && (
        <>
          <h2 style={styles.header}>Genders:</h2>
          <button
            onClick={() =>
              setGenders(
                genders.map((gender) => {
                  gender.checked = !gender.checked;

                  return gender;
                }),
              )
            }
            type="button"
          >
            Toggle All
          </button>
          <CheckboxList
            items={genders}
            onChange={(flag) =>
              setGenders(
                genders.map((gender) => {
                  if (gender.flag === flag) {
                    gender.checked = !gender.checked;
                  }

                  return gender;
                }),
              )
            }
          />
          <h2 style={styles.header}>Jobs:</h2>
          <button
            onClick={() =>
              setJobs(
                jobs.map((job) => {
                  job.checked = !job.checked;

                  return job;
                }),
              )
            }
            type="button"
          >
            Toggle All
          </button>
          <CheckboxList
            items={jobs}
            onChange={(flag) =>
              setJobs(
                jobs.map((job) => {
                  if (job.flag === flag) {
                    job.checked = !job.checked;
                  }

                  return job;
                }),
              )
            }
          />
          <h2 style={styles.header}>Races:</h2>
          <button
            onClick={() =>
              setRaces(
                races.map((race) => {
                  race.checked = !race.checked;

                  return race;
                }),
              )
            }
            type="button"
          >
            Toggle All
          </button>
          <CheckboxList
            items={races}
            onChange={(flag) =>
              setRaces(
                races.map((race) => {
                  if (race.flag === flag) {
                    race.checked = !race.checked;
                  }

                  return race;
                }),
              )
            }
          />
        </>
      )}
    </div>
  );
}

const styles = {
  button: {
    color: 'blue',
    cursor: 'pointer',
    display: 'inline-block',
    padding: '5px 10px',
  },
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '5px 0',
  },
  container: {},
  selected: {
    textDecoration: 'underline',
  },
};
