import React, { useState } from 'react';

import BitArray from '../BitArray';
import { decode, genderMask, jobMask, raceMask } from '../NPCConsts';
import NPCMaskGenerator from './NPCMaskGenerator';

const nameTypes = {
  0: 'Both',
  1: 'First Name',
  2: 'Last Name',
};

export default function ({ names, onAdd, onDelete, onEdit }) {
  const [filter, setFilter] = useState(
    genderMask.or(jobMask).or(raceMask).toString(),
  );
  const [type, setType] = useState('0');
  const [search, setSearch] = useState('');
  const [tempName, setTempName] = useState(null);

  const filteredNames = names.filter(
    (name) =>
      new BitArray(name.tag).hasAnyFromEach(
        genderMask.and(filter),
        jobMask.and(filter),
        raceMask.and(filter),
      ) &&
      name.value.indexOf(search) !== -1 &&
      (+type === 0 || name.type === +type),
  );

  return (
    <>
      {tempName === null && (
        <>
          <NPCMaskGenerator
            label="Filter: "
            mask={filter}
            onGenerate={setFilter}
          />
          <input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            style={styles.search}
            type="text"
            value={search}
          />
          <div>
            <span>Type: </span>
            {Object.keys(nameTypes).map((key) => (
              <label key={key} style={styles.radio}>
                {nameTypes[key]}{' '}
                <input
                  checked={+type === +key}
                  name="type"
                  onChange={() => setType(key)}
                  type="radio"
                  value={key}
                />
              </label>
            ))}
          </div>
          <button
            onClick={() =>
              setTempName({
                index: null,
                tag: jobMask.toString(),
                type: null,
                value: '',
              })
            }
            type="button"
          >
            + Add Name
          </button>
          <div>
            <div>Count: {filteredNames.length}</div>
            {filteredNames
              .sort((a, b) => (a.value > b.value ? 1 : -1))
              .map((name) => (
                <div
                  key={name.index + '-' + name.value}
                  onClick={() =>
                    setTempName({
                      index: name.index,
                      tag: name.tag,
                      type: name.type,
                      value: name.value,
                    })
                  }
                  style={styles.row}
                >
                  <span title={decode(name.tag)}>{name.tag}</span> -{' '}
                  {nameTypes[name.type]} - {name.value}
                </div>
              ))}
          </div>
        </>
      )}
      {tempName !== null && (
        <>
          <NPCMaskGenerator
            label="Tag: "
            mask={tempName.tag}
            onGenerate={(mask) =>
              setTempName({
                index: tempName.index,
                tag: mask,
                type: tempName.type,
                value: tempName.value,
              })
            }
          />
          <div>
            <span>Type: </span>
            {Object.keys(nameTypes)
              .filter((key) => key > 0)
              .map((key) => (
                <label key={key} style={styles.radio}>
                  {nameTypes[key]}{' '}
                  <input
                    checked={+tempName.type === +key}
                    name="type"
                    onChange={() =>
                      setTempName({
                        index: tempName.index,
                        tag: tempName.tag,
                        type: +key,
                        value: tempName.value,
                      })
                    }
                    type="radio"
                    value={key}
                  />
                </label>
              ))}
          </div>
          <input
            onChange={(e) =>
              setTempName({
                index: tempName.index,
                tag: tempName.tag,
                type: tempName.type,
                value: e.target.value,
              })
            }
            placeholder="Name"
            style={styles.input}
            value={tempName.value}
          />
          <button
            onClick={() => {
              if (
                !genderMask.hasAny(tempName.tag) ||
                !jobMask.hasAny(tempName.tag) ||
                !raceMask.hasAny(tempName.tag)
              ) {
                window.error('Must choose at least 1 gender, job, and race.');
                return;
              }

              if (tempName.type === null) {
                window.error('Type must be selected.');
                return;
              }

              tempName.value = tempName.value.trim();
              if (tempName.value === '') {
                window.error('Name cannot be blank.');
                return;
              }

              const index = tempName.index;
              delete tempName.index;

              if (index === null) {
                onAdd(tempName);
              } else {
                onEdit(tempName, index);
              }

              setTempName(null);
            }}
            type="button"
          >
            Save
          </button>
          <button onClick={() => setTempName(null)} type="button">
            Cancel
          </button>
          {tempName.index !== null && (
            <button
              onClick={() => {
                if (window.confirm('Delete?')) {
                  onDelete(tempName.index);
                  setTempName(null);
                }
              }}
              type="button"
            >
              Delete
            </button>
          )}
        </>
      )}
    </>
  );
}

const styles = {
  input: {
    display: 'block',
    width: '300px',
  },
  radio: {
    margin: '0 5px',
  },
  row: {
    borderBottom: '1px solid #000',
    cursor: 'pointer',
    padding: '10px',
  },
  search: {
    display: 'block',
  },
  token: {
    backgroundColor: '#eee',
    margin: '0 5px',
    padding: '0 5px',
  },
};
