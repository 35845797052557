import React, { useState } from 'react';

import { itemTypes } from '../itemConsts';
import ItemMaskGenerator from './ItemMaskGenerator';

export default function ({ names, onAdd, onDelete, onEdit }) {
  const [itemTypeFilter, setItemTypeFilter] = useState(itemTypes.mask);
  const [search, setSearch] = useState('');
  const [tempName, setTempName] = useState(null);

  const filteredNames = names
    .filter(({ itemType }) => itemTypeFilter.hasAny(itemType))
    .filter(({ value }) => value.indexOf(search) !== -1);

  return (
    <>
      {tempName === null && (
        <>
          <ItemMaskGenerator
            mask={itemTypeFilter}
            onGenerate={setItemTypeFilter}
            type="itemType"
          />
          <input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            style={styles.search}
            type="text"
            value={search}
          />
          <button
            onClick={() =>
              setTempName({
                index: null,
                itemType: null,
                value: '',
              })
            }
            type="button"
          >
            + Add Name
          </button>
          <div>
            <div>Count: {filteredNames.length}</div>
            {filteredNames
              .sort((a, b) => (a.value > b.value ? 1 : -1))
              .map((name) => (
                <div
                  key={name.index + '-' + name.value}
                  onClick={() => setTempName({ ...name })}
                  style={styles.row}
                >
                  <span title={itemTypes.decode(name.itemType)}>
                    {name.itemType}
                  </span>{' '}
                  - {name.value}
                </div>
              ))}
          </div>
        </>
      )}
      {tempName !== null && (
        <>
          <ItemMaskGenerator
            mask={tempName.itemType}
            onGenerate={(itemType) =>
              setTempName({
                ...tempName,
                itemType: itemType.toString(),
              })
            }
            type="itemType"
          />
          <input
            onChange={(e) =>
              setTempName({
                ...tempName,
                value: e.target.value,
              })
            }
            placeholder="Name"
            style={styles.input}
            value={tempName.value}
          />
          <button
            onClick={() => {
              if (!itemTypes.mask.hasAny(tempName.itemType)) {
                window.error('Must choose at least 1 item type.');
                return;
              }

              tempName.value = tempName.value.trim();
              if (tempName.value === '') {
                window.error('Name cannot be blank.');
                return;
              }

              const index = tempName.index;
              delete tempName.index;

              if (index === null) {
                onAdd(tempName);
              } else {
                onEdit(tempName, index);
              }

              setTempName(null);
            }}
            type="button"
          >
            Save
          </button>
          <button onClick={() => setTempName(null)} type="button">
            Cancel
          </button>
          {tempName.index !== null && (
            <button
              onClick={() => {
                if (window.confirm('Delete?')) {
                  onDelete(tempName.index);
                  setTempName(null);
                }
              }}
              type="button"
            >
              Delete
            </button>
          )}
        </>
      )}
    </>
  );
}

const styles = {
  input: {
    display: 'block',
    width: '300px',
  },
  radio: {
    margin: '0 5px',
  },
  row: {
    borderBottom: '1px solid #000',
    cursor: 'pointer',
    padding: '10px',
  },
  search: {
    display: 'block',
  },
  token: {
    backgroundColor: '#eee',
    margin: '0 5px',
    padding: '0 5px',
  },
};
