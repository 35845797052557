import BitArray from './BitArray';

const genders = [
  { flag: '1', label: 'Female', value: 'female' },
  { flag: '10', label: 'Male', value: 'male' },
];

const genderMask = BitArray.fromFlags(...genders.map(({ flag }) => flag));

const jobs = [
  {
    flag: '100',
    label: 'Adventurer',
    value: 'adventurer',
  },
  {
    flag: '1000',
    label: 'Clergy',
    value: 'clergy',
  },
  {
    flag: '10000',
    label: 'Entertainer',
    value: 'entertainer',
  },
  {
    flag: '100000',
    label: 'Guard',
    value: 'guard',
  },
  {
    flag: '10000000',
    label: 'Innkeeper',
    value: 'innkeeper',
  },
  {
    flag: '100000000',
    label: 'Merchant',
    value: 'merchant',
  },
  {
    flag: '1000000000',
    label: 'Noble',
    value: 'noble',
  },
  {
    flag: '10000000000',
    label: 'Scholar',
    value: 'scholar',
  },
  {
    flag: '100000000000',
    label: 'Spellcaster',
    value: 'spellcaster',
  },
  {
    flag: '1000000000000',
    label: 'Thief',
    value: 'thief',
  },
  {
    flag: '10000000000000',
    label: 'Thug',
    value: 'thug',
  },
  {
    flag: '100000000000000',
    label: 'Villain',
    value: 'villain',
  },
  {
    flag: '1000000000000000',
    label: 'Villager',
    value: 'villager',
  },
  {
    flag: '1000000',
    label: 'Wanderer',
    value: 'wanderer',
  },
  {
    flag: '10000000000000000000000000000000',
    label: 'Worker',
    value: 'worker',
  },
];

const jobMask = BitArray.fromFlags(...jobs.map(({ flag }) => flag));

const races = [
  {
    flag: '1000000000000000000000000000000',
    label: 'Creature',
    value: 'creature',
  },
  {
    flag: '10000000000000000',
    label: 'Demon Folk',
    value: 'demonfolk',
  },
  {
    flag: '100000000000000000',
    label: 'Dragon Folk',
    value: 'dragonfolk',
  },
  {
    flag: '1000000000000000000',
    label: 'Dwarf (Dark)',
    value: 'dwarfdark',
  },
  {
    flag: '10000000000000000000',
    label: 'Dwarf (Hill)',
    value: 'dwarfhill',
  },
  {
    flag: '100000000000000000000',
    label: 'Dwarf (Mountain)',
    value: 'dwarfmountain',
  },
  {
    flag: '1000000000000000000000',
    label: 'Elf (Dark)',
    value: 'elfdark',
  },
  {
    flag: '10000000000000000000000',
    label: 'Elf (High)',
    value: 'elfhigh',
  },
  {
    flag: '100000000000000000000000',
    label: 'Elf (Sea)',
    value: 'elfsea',
  },
  {
    flag: '1000000000000000000000000',
    label: 'Elf (Wood)',
    value: 'elfwood',
  },
  { flag: '10000000000000000000000000', label: 'Gnome', value: 'gnome' },
  {
    flag: '100000000000000000000000000',
    label: 'Halfling',
    value: 'halfling',
  },
  {
    flag: '1000000000000000000000000000',
    label: 'Half Elf',
    value: 'halfelf',
  },
  {
    flag: '10000000000000000000000000000',
    label: 'Half Orc',
    value: 'halforc',
  },
  {
    flag: '100000000000000000000000000000',
    label: 'Human',
    value: 'human',
  },
];

const raceMask = BitArray.fromFlags(...races.map(({ flag }) => flag));

function decode(mask) {
  mask = new BitArray(mask);

  let matches = [];

  let matchingGenders = [];
  for (let gender of genders) {
    if (mask.hasFlag(gender.flag)) {
      matchingGenders.push(gender.label);
    }
  }

  if (matchingGenders.length) {
    matches.push('Genders: ' + matchingGenders.join(', '));
  }

  let matchingJobs = [];
  for (let job of jobs) {
    if (mask.hasFlag(job.flag)) {
      matchingJobs.push(job.label);
    }
  }

  if (matchingJobs.length) {
    matches.push('Jobs: ' + matchingJobs.join(', '));
  }

  let matchingRaces = [];
  for (let race of races) {
    if (mask.hasFlag(race.flag)) {
      matchingRaces.push(race.label);
    }
  }

  if (matchingRaces.length) {
    matches.push('Races: ' + matchingRaces.join(', '));
  }

  return matches.join('\n');
}

export { decode, genders, genderMask, jobs, jobMask, races, raceMask };
