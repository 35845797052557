import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import React, { useState } from 'react';

async function login(email, password, setNewPasswordRequired, setError) {
  try {
    setError('');

    const user = await Auth.signIn(email, password);
    const challengeName = user.challengeName;

    if (challengeName === 'NEW_PASSWORD_REQUIRED') {
      setNewPasswordRequired(true);
      setError('Please set a new password.');
    } else if (
      challengeName === 'SMS_MFA' ||
      challengeName === 'SOFTWARE_TOKEN_MFA' ||
      challengeName === 'MFA_SETUP'
    ) {
      throw new Error(
        'Multi-Factor Authentication not supported.',
        'MFANotSupportedException',
      );
    }

    return user;
  } catch (err) {
    if (
      err.code === 'UserNotFoundException' ||
      err.code === 'NotAuthorizedException'
    ) {
      setError('Invalid email or password.');
    } else {
      console.error(err);
    }

    return null;
  }
}

function setCredentials(user) {
  if (user === null) {
    return;
  }

  const config = Auth.configure();
  let Logins = {};

  const userId =
    user.storage[
      `CognitoIdentityServiceProvider.${AWS.awsConfig.appClientId}.LastAuthUser`
    ];

  const token =
    user.storage[
      `CognitoIdentityServiceProvider.${AWS.awsConfig.appClientId}.${userId}.idToken`
    ];

  Logins[
    `cognito-idp.${config.region}.amazonaws.com/${config.userPoolId}`
  ] = token;

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.identityPoolId,
    Logins,
  });
}

export default function({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [user, setUser] = useState(null);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [error, setError] = useState('');

  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  async function finishLogin(user) {
    if (user === null) {
      return;
    }

    setUser(user);
    if (user.challengeName) {
      return;
    }

    setCredentials(user);
    onLogin(true);
  }

  async function submitLogin() {
    finishLogin(await login(email, password, setNewPasswordRequired, setError));
  }

  async function submitNewPassword() {
    if (!givenName || !familyName) {
      setError('Please enter your name.');
      return;
    }

    if (newPassword1 === newPassword2) {
      finishLogin(
        await Auth.completeNewPassword(user, newPassword1, {
          email,
          given_name: givenName,
          family_name: familyName,
        }),
      );
    } else {
      setError('Passwords do not match.');
    }
  }

  return (
    <div>
      <span style={styles.error}>{error}</span>
      {!newPasswordRequired && (
        <>
          <input
            onChange={({ target: { value } }) => setEmail(value)}
            placeholder="Email"
            style={styles.input}
            type="email"
            value={email}
          />
          <input
            onChange={({ target: { value } }) => setPassword(value)}
            onKeyDown={({ key }) => key === 'Enter' && submitLogin()}
            placeholder="Password"
            style={styles.input}
            type="password"
            value={password}
          />
          <button type="button" onClick={submitLogin}>
            Login
          </button>
        </>
      )}
      {newPasswordRequired && (
        <>
          <input
            onChange={({ target: { value } }) => setGivenName(value)}
            placeholder="Given Name"
            style={styles.input}
            type="text"
            value={givenName}
          />
          <input
            onChange={({ target: { value } }) => setFamilyName(value)}
            placeholder="Family Name"
            style={styles.input}
            type="text"
            value={familyName}
          />
          <input
            onChange={({ target: { value } }) => setNewPassword1(value)}
            placeholder="New Password"
            style={styles.input}
            type="password"
            value={newPassword1}
          />
          <input
            onChange={({ target: { value } }) => setNewPassword2(value)}
            onKeyDown={({ key }) => key === 'Enter' && submitNewPassword()}
            placeholder="New Password Again"
            style={styles.input}
            type="password"
            value={newPassword2}
          />
          <button type="button" onClick={submitNewPassword}>
            Login
          </button>
        </>
      )}
    </div>
  );
}

const styles = {
  error: {
    color: 'red',
    display: 'block',
    marginBottom: 5,
  },
  input: {
    display: 'block',
  },
};
