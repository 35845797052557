import React from 'react';

export default function({ onSelect, selected }) {
  if (selected === null) {
    selected = {};
  }

  const apps = {
    magwas: {
      folder: 'magwas-magic-item-compendium',
      id: 'com.knightsofvasteel.magwasmagicitemcompendium',
    },
    npc: {
      folder: 'npc-for-hire',
      id: 'com.knightsofvasteel.npcforhire',
    },
  };

  return (
    <div style={styles.container}>
      <span>App:</span>
      <div
        onClick={() => onSelect(apps.npc)}
        style={Object.assign(
          {},
          styles.button,
          selected.id === apps.npc.id && styles.selected,
        )}
      >
        NPC For Hire
      </div>
      <div
        onClick={() => onSelect(apps.magwas)}
        style={Object.assign(
          {},
          styles.button,
          selected.id === apps.magwas.id && styles.selected,
        )}
      >
        Magwa&apos;s Magic Item Compendium
      </div>
    </div>
  );
}

const styles = {
  button: {
    color: 'blue',
    cursor: 'pointer',
    display: 'inline-block',
    padding: '5px 10px',
  },
  container: {},
  selected: {
    textDecoration: 'underline',
  },
};
