const awsConfig = {
  appClientId: '7s706ddae4q7t0d1a0m365dt6t',
  bucketName: 'cdn.knightsofvasteel.com',
  cognitoIdentityPoolId: 'us-west-2:5851449f-c9a6-4384-b381-8044aea4d097',
  cognitoUserPoolId: 'us-west-2_t7TmgV4hb',
  distributionId: 'E3R4HDLQ07JKHV',
  region: 'us-west-2',
};

export default awsConfig;
