import React, { useState } from 'react';

import BitArray from '../BitArray';
import { decode, genderMask, jobMask, raceMask } from '../NPCConsts';
import NPCMaskGenerator from './NPCMaskGenerator';

function preview(text) {
  return text
    .replace(/FullName/g, 'Thia Silverton')
    .replace(/FirstName/g, 'Thia')
    .replace(/LastName/g, 'Silverton')
    .replace(/\bHe\b/g, 'She')
    .replace(/\bhe\b/g, 'she')
    .replace(/\b([Hh])i[ms]\b/g, '$1er')
    .replace(/\b([Hh])imself\b/g, '$1erself');
}

export default function ({ bios, onAdd, onDelete, onEdit }) {
  const [filter, setFilter] = useState(
    genderMask.or(jobMask).or(raceMask).toString(),
  );
  const [search, setSearch] = useState('');
  const [tempBio, setTempBio] = useState(null);

  const filteredBios = bios
    .filter((bio) =>
      new BitArray(bio.tag).hasAnyFromEach(
        genderMask.and(filter),
        jobMask.and(filter),
        raceMask.and(filter),
      ),
    )
    .filter((bio) => bio.value.indexOf(search) !== -1);

  return (
    <>
      {tempBio === null && (
        <>
          <NPCMaskGenerator
            label="Filter: "
            mask={filter}
            onGenerate={setFilter}
          />
          <input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            style={styles.search}
            type="text"
            value={search}
          />
          <button
            onClick={() =>
              setTempBio({
                index: null,
                tag: '',
                value: '',
              })
            }
            type="button"
          >
            + Add Bio
          </button>
          <div>
            <div>Count: {filteredBios.length}</div>
            {filteredBios.map((bio) => (
              <div
                key={bio.index}
                onClick={() =>
                  setTempBio({
                    index: bio.index,
                    tag: bio.tag,
                    value: bio.value,
                  })
                }
                style={styles.row}
              >
                <span title={decode(bio.tag)}>{bio.tag}</span> - {bio.value}
              </div>
            ))}
          </div>
        </>
      )}
      {tempBio !== null && (
        <>
          <NPCMaskGenerator
            label="Tag: "
            mask={tempBio.tag}
            onGenerate={(mask) =>
              setTempBio({
                index: tempBio.index,
                tag: mask,
                value: tempBio.value,
              })
            }
          />
          <div>
            Available tokens:
            <span style={styles.token}>FirstName</span>
            <span style={styles.token}>LastName</span>
            <span style={styles.token}>FullName</span>
            <span style={styles.token}>He</span>
            <span style={styles.token}>Him</span>
            <span style={styles.token}>His</span>
            <span style={styles.token}>Himself</span>
          </div>
          <textarea
            onChange={(e) =>
              setTempBio({
                index: tempBio.index,
                tag: tempBio.tag,
                value: e.target.value,
              })
            }
            rows="10"
            style={styles.textarea}
            value={tempBio.value}
          />
          <div style={styles.preview}>{preview(tempBio.value)}</div>
          <button
            onClick={() => {
              if (
                !genderMask.hasAny(tempBio.tag) ||
                !jobMask.hasAny(tempBio.tag) ||
                !raceMask.hasAny(tempBio.tag)
              ) {
                window.error('Must choose at least 1 gender, job, and race.');
                return;
              }

              tempBio.value = tempBio.value.trim();
              if (tempBio.value === '') {
                window.error('Bio cannot be blank.');
                return;
              }

              const index = tempBio.index;
              delete tempBio.index;

              if (index === null) {
                onAdd(tempBio);
              } else {
                onEdit(tempBio, index);
              }

              setTempBio(null);
            }}
            type="button"
          >
            Save
          </button>
          <button onClick={() => setTempBio(null)} type="button">
            Cancel
          </button>
          {tempBio.index !== null && (
            <button
              onClick={() => {
                if (window.confirm('Delete?')) {
                  onDelete(tempBio.index);
                  setTempBio(null);
                }
              }}
              type="button"
            >
              Delete
            </button>
          )}
        </>
      )}
    </>
  );
}

const styles = {
  preview: {
    backgroundColor: '#eee',
    padding: '15px',
    whiteSpace: 'pre-wrap',
  },
  row: {
    borderBottom: '1px solid #000',
    cursor: 'pointer',
    padding: '10px',
  },
  search: {
    display: 'block',
  },
  textarea: {
    width: '100%',
  },
  token: {
    backgroundColor: '#eee',
    margin: '0 5px',
    padding: '0 5px',
  },
};
