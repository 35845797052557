import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import s3 from '../s3';
import AppSelector from './AppSelector';
import InvalidationButton from './InvalidationButton';
import ItemTypeSelector from './ItemTypeSelector';
import Login from './Login';
import ManageItemDescriptions from './ManageItemDescriptions';
import ManageItemMechanics from './ManageItemMechanics';
import ManageItemNames from './ManageItemNames';
import ManageItemPacks from './ManageItemPacks';
import ManageItemPromo from './ManageItemPromo';
import ManageItemStatics from './ManageItemStatics';
import ManageNPCBios from './ManageNPCBios';
import ManageNPCNames from './ManageNPCNames';
import ManageNPCPacks from './ManageNPCPacks';
import ManageNPCPromo from './ManageNPCPromo';
import NPCTypeSelector from './NPCTypeSelector';

async function fetchFiles(app, setFiles) {
  if (app === null) {
    return;
  }

  let files = {};
  files.content = await s3.getJSON(`public/${app.folder}/content.json`);
  files.packs = await s3.getJSON(`public/${app.folder}/packs.json`);
  files.promo = await s3.getJSON(`public/${app.folder}/promo.json`);
  files.versions = await s3.getJSON(`public/${app.folder}/versions.json`);

  setFiles(files);
}

async function saveContent(app, files, setFiles) {
  files.versions.contentVersion++;
  setFiles(files);

  await s3.putJSON(`public/${app.folder}/content.json`, files.content);
  await s3.putJSON(`public/${app.folder}/versions.json`, files.versions);
}

async function savePacks(app, files, setFiles) {
  files.versions.packVersion++;
  setFiles(files);

  await s3.putJSON(`public/${app.folder}/packs.json`, files.packs);
  await s3.putJSON(`public/${app.folder}/versions.json`, files.versions);
}

async function savePromo(app, files, setFiles) {
  files.versions.promoVersion++;
  setFiles(files);

  await s3.putJSON(`public/${app.folder}/promo.json`, files.promo);
  await s3.putJSON(`public/${app.folder}/versions.json`, files.versions);
}

export default function () {
  const [loggedIn, setLoggedIn] = useState(false);
  const [app, setApp] = useState(null);
  const [type, setType] = useState('');

  const [files, setFiles] = useState(null);
  if (loggedIn && files === null) {
    fetchFiles(app, setFiles);
  }

  const { addToast } = useToasts();

  window.error = function (message) {
    addToast(message, { appearance: 'error' });
  };
  window.success = function (message) {
    addToast(message, { appearance: 'success' });
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>CDN Content Management</h1>
      {!loggedIn && <Login onLogin={() => setLoggedIn(true)} />}
      {loggedIn && (
        <>
          <AppSelector
            onSelect={(app) => {
              fetchFiles(app, setFiles);
              setApp(app);
            }}
            selected={app}
          />
          <InvalidationButton />
          {app !== null && app.id === 'com.knightsofvasteel.npcforhire' && (
            <>
              {files !== null && (
                <NPCTypeSelector onSelect={setType} selected={type} />
              )}
              {type === 'bios' && (
                <ManageNPCBios
                  bios={files.content.bios.map((bio, index) =>
                    Object.assign({ index }, bio),
                  )}
                  onAdd={async (bio) => {
                    let newFiles = Object.assign({}, files);
                    newFiles.content.bios.push(bio);
                    await saveContent(app, newFiles, setFiles);
                    window.success('Bio added.');
                  }}
                  onDelete={async (index) => {
                    let newFiles = Object.assign({}, files);
                    newFiles.content.bios.splice(index, 1);
                    await saveContent(app, newFiles, setFiles);
                    window.success('Bio deleted.');
                  }}
                  onEdit={async (bio, index) => {
                    let newFiles = Object.assign({}, files);
                    newFiles.content.bios[index] = bio;
                    await saveContent(app, newFiles, setFiles);
                    window.success('Bio edited.');
                  }}
                />
              )}
              {type === 'names' && (
                <ManageNPCNames
                  names={files.content.names.map((name, index) =>
                    Object.assign({ index }, name),
                  )}
                  onAdd={async (name) => {
                    let newFiles = Object.assign({}, files);
                    newFiles.content.names.push(name);
                    await saveContent(app, newFiles, setFiles);
                    window.success('Name added.');
                  }}
                  onDelete={async (index) => {
                    let newFiles = Object.assign({}, files);
                    newFiles.content.names.splice(index, 1);
                    await saveContent(app, newFiles, setFiles);
                    window.success('Name deleted.');
                  }}
                  onEdit={async (name, index) => {
                    let newFiles = Object.assign({}, files);
                    newFiles.content.names[index] = name;
                    await saveContent(app, newFiles, setFiles);
                    window.success('Name edited.');
                  }}
                />
              )}
              {type === 'packs' && (
                <ManageNPCPacks
                  onDelete={async (id) => {
                    let newFiles = Object.assign({}, files);
                    newFiles.packs = files.packs.filter(
                      (pack) => pack.id !== id,
                    );
                    await savePacks(app, newFiles, setFiles);
                    window.success('Pack deleted.');
                  }}
                  onSave={async (pack) => {
                    let editing = files.packs.reduce(
                      (editing, p) =>
                        editing ? true : p.id === pack.originalId,
                      false,
                    );

                    let newFiles = Object.assign({}, files);

                    if (editing) {
                      // the pack's ID was renamed
                      if (pack.originalId !== pack.id) {
                        // move images
                        for (
                          let index = 0, l = pack.images.length;
                          index < l;
                          index++
                        ) {
                          await s3.moveObject(
                            `protected/${app.folder}/${pack.originalId}/${index}.jpg`,
                            `protected/${app.folder}/${pack.id}/${index}.jpg`,
                          );
                          await s3.moveObject(
                            `public/${app.folder}/${pack.originalId}/${index}.jpg`,
                            `public/${app.folder}/${pack.id}/${index}.jpg`,
                          );
                        }
                      }

                      newFiles.packs = files.packs.map((p) => {
                        if (p.id === pack.originalId) {
                          delete pack.originalId;
                          return pack;
                        }

                        return p;
                      });

                      await savePacks(app, newFiles, setFiles);
                      window.success('Pack edited.');
                    } else {
                      delete pack.originalId;
                      newFiles.packs.push(pack);

                      await savePacks(app, newFiles, setFiles);
                      window.success('Pack added.');
                    }
                  }}
                  packs={files.packs}
                />
              )}
              {type === 'promo' && (
                <ManageNPCPromo
                  onSave={async (promo) => {
                    let newFiles = Object.assign({}, files);
                    newFiles.promo = Object.assign({ displayed: false }, promo);
                    await savePromo(app, newFiles, setFiles);
                    window.success('Promo edited.');
                  }}
                  promo={files.promo}
                />
              )}
            </>
          )}
          {app !== null &&
            app.id === 'com.knightsofvasteel.magwasmagicitemcompendium' && (
              <>
                {files !== null && (
                  <ItemTypeSelector onSelect={setType} selected={type} />
                )}
                {type === 'descriptions' && (
                  <ManageItemDescriptions
                    descriptions={files.content.descriptions.map(
                      (description, index) =>
                        Object.assign({ index }, description),
                    )}
                    onAdd={async (description) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.content.descriptions.push(description);
                      await saveContent(app, newFiles, setFiles);
                      window.success('Description added.');
                    }}
                    onDelete={async (index) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.content.descriptions.splice(index, 1);
                      await saveContent(app, newFiles, setFiles);
                      window.success('Description deleted.');
                    }}
                    onEdit={async (description, index) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.content.descriptions[index] = description;
                      await saveContent(app, newFiles, setFiles);
                      window.success('Description edited.');
                    }}
                  />
                )}
                {type === 'mechanics' && (
                  <ManageItemMechanics
                    mechanics={files.content.mechanics.map((mechanic, index) =>
                      Object.assign({ index }, mechanic),
                    )}
                    onAdd={async (mechanic) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.content.mechanics.push(mechanic);
                      await saveContent(app, newFiles, setFiles);
                      window.success('Mechanic added.');
                    }}
                    onDelete={async (index) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.content.mechanics.splice(index, 1);
                      await saveContent(app, newFiles, setFiles);
                      window.success('Mechanic deleted.');
                    }}
                    onEdit={async (mechanic, index) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.content.mechanics[index] = mechanic;
                      await saveContent(app, newFiles, setFiles);
                      window.success('Mechanic edited.');
                    }}
                  />
                )}
                {type === 'names' && (
                  <ManageItemNames
                    names={files.content.names.map((name, index) =>
                      Object.assign({ index }, name),
                    )}
                    onAdd={async (name) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.content.names.push(name);
                      await saveContent(app, newFiles, setFiles);
                      window.success('Name added.');
                    }}
                    onDelete={async (index) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.content.names.splice(index, 1);
                      await saveContent(app, newFiles, setFiles);
                      window.success('Name deleted.');
                    }}
                    onEdit={async (name, index) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.content.names[index] = name;
                      await saveContent(app, newFiles, setFiles);
                      window.success('Name edited.');
                    }}
                  />
                )}
                {type === 'packs' && (
                  <ManageItemPacks
                    onDelete={async (id) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.packs = files.packs.filter(
                        (pack) => pack.id !== id,
                      );
                      await savePacks(app, newFiles, setFiles);
                      window.success('Pack deleted.');
                    }}
                    onSave={async (pack) => {
                      let editing = files.packs.reduce(
                        (editing, p) =>
                          editing ? true : p.id === pack.originalId,
                        false,
                      );

                      let newFiles = Object.assign({}, files);

                      if (editing) {
                        // the pack's ID was renamed
                        if (pack.originalId !== pack.id) {
                          // move images
                          for (
                            let index = 0, l = pack.images.length;
                            index < l;
                            index++
                          ) {
                            await s3.moveObject(
                              `protected/${app.folder}/${pack.originalId}/${index}.jpg`,
                              `protected/${app.folder}/${pack.id}/${index}.jpg`,
                            );
                            await s3.moveObject(
                              `public/${app.folder}/${pack.originalId}/${index}.jpg`,
                              `public/${app.folder}/${pack.id}/${index}.jpg`,
                            );
                          }
                        }

                        newFiles.packs = files.packs.map((p) => {
                          if (p.id === pack.originalId) {
                            delete pack.originalId;
                            return pack;
                          }

                          return p;
                        });

                        await savePacks(app, newFiles, setFiles);
                        window.success('Pack edited.');
                      } else {
                        delete pack.originalId;
                        newFiles.packs.push(pack);

                        await savePacks(app, newFiles, setFiles);
                        window.success('Pack added.');
                      }
                    }}
                    packs={files.packs}
                  />
                )}
                {type === 'promo' && (
                  <ManageItemPromo
                    onSave={async (promo) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.promo = Object.assign(
                        { displayed: false },
                        promo,
                      );
                      await savePromo(app, newFiles, setFiles);
                      window.success('Promo edited.');
                    }}
                    promo={files.promo}
                  />
                )}
                {type === 'statics' && (
                  <ManageItemStatics
                    onAdd={async (staticItem) => {
                      let newFiles = Object.assign({}, files);
                      let { type, ...item } = staticItem;

                      if (!newFiles.content[type]) {
                        newFiles.content[type] = [];
                      }

                      newFiles.content[type].push(item);
                      await saveContent(app, newFiles, setFiles);
                      window.success('Static added.');
                    }}
                    onDelete={async (type, index) => {
                      let newFiles = Object.assign({}, files);
                      newFiles.content[type].splice(index, 1);
                      await saveContent(app, newFiles, setFiles);
                      window.success('Static deleted.');
                    }}
                    onEdit={async (staticItem, index) => {
                      let newFiles = Object.assign({}, files);
                      let { type, ...item } = staticItem;
                      newFiles.content[type][index] = item;
                      await saveContent(app, newFiles, setFiles);
                      window.success('Static edited.');
                    }}
                    statics={files.content.magwas
                      .map((staticItem, index) => ({
                        ...staticItem,
                        index,
                        type: 'magwas',
                      }))
                      .concat(
                        files.content.srd.map((staticItem, index) => ({
                          ...staticItem,
                          index,
                          type: 'srd',
                        })),
                      )
                      .concat(
                        (files.content.discord || []).map(
                          (staticItem, index) => ({
                            ...staticItem,
                            index,
                            type: 'discord',
                          }),
                        ),
                      )}
                  />
                )}
              </>
            )}
        </>
      )}
    </div>
  );
}

const styles = {
  container: {
    padding: 15,
  },
  header: {
    fontSize: 24,
    margin: 0,
    marginBottom: 5,
  },
};
