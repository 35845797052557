/**
 * Creates a representation of bits
 * @param array|number|BitArray|string bits
 *   array - must consist of 0's and 1's only
 *   number - must be < 2,147,483,648
 *   string - must consist of 0's and 1's only
 */
export default function BitArray(bits) {
  this.bits = [];
  this.length = 0;

  if (Array.isArray(bits)) {
    this.bits = bits.slice();
  } else if (Number.isInteger(bits)) {
    bits = (bits >>> 0).toString(2); // eslint-disable-line no-bitwise
  } else if (bits instanceof BitArray) {
    this.bits = bits.bits.slice();
  }

  if (typeof bits === 'string') {
    for (let i = 0, l = bits.length; i < l; i++) {
      this.bits.push(+bits[i]);
    }
  }

  this.length = this.bits.length;
}

/**
 * Creates a BitArray from the given flags
 * @param array flags - Returned BitArray will be the flags OR-ed together
 * @return BitArray
 */
BitArray.fromFlags = (...flags) =>
  flags.reduce((mask, flag) => mask.or(flag), new BitArray(0));

BitArray.prototype = {
  /**
   * Internal function that copies this and the given bits into new BitArrays
   * @param array|number|BitArray|string bits
   */
  _equalize: function (bits) {
    let thisCopy = new BitArray(this);
    let bitsCopy = new BitArray(bits);

    if (bitsCopy.length < thisCopy.length) {
      bitsCopy.extendTo(thisCopy.length);
    } else {
      thisCopy.extendTo(bitsCopy.length);
    }

    return [thisCopy, bitsCopy];
  },

  /**
   * ANDs this and the given mask together to produce a new BitArray
   * @param array|number|BitArray|string mask
   * @return BitArray
   */
  and: function (mask) {
    let [thisCopy, maskCopy] = this._equalize(mask);

    let arr = [];
    for (let i = 0, l = thisCopy.length; i < l; i++) {
      arr.push(thisCopy.bits[i] & maskCopy.bits[i]); // eslint-disable-line no-bitwise
    }

    thisCopy.bits = arr;
    return thisCopy;
  },

  /**
   * Makes sure this BitArray equals the given bits
   * @param array|number|BitArray|string bits
   * @return bool
   */
  equals: function (bits) {
    let [thisCopy, bitsCopy] = this._equalize(bits);

    for (let i = 0, l = thisCopy.length; i < l; i++) {
      if (thisCopy.bits[i] !== bitsCopy.bits[i]) {
        return false;
      }
    }

    return true;
  },

  /**
   * Makes sure this BitArray is at least the given length
   * @param number length - The minimum length
   */
  extendTo: function (length) {
    while (this.bits.length < length) {
      this.bits.unshift(0);
    }

    this.length = this.bits.length;
  },

  /**
   * Checks if this BitArray has all the bits from the mask set
   * @param array mask - The mask to check against
   * @return bool
   */
  hasAll: function (mask) {
    return this.and(mask).equals(mask);
  },

  /**
   * Checks if this BitArray has all of the given flags set
   * @param array flags - The flags to check against
   * @return bool
   */
  hasAllFlags: function (...flags) {
    return this.hasAll(BitArray.fromFlags(...flags));
  },

  /**
   * Checks if this BitArray has all the bits from the masks set
   * @param array masks - The masks to check against
   * @return bool
   */
  hasAllFromEach: function (...masks) {
    for (let mask of masks) {
      if (!this.hasAll(mask)) {
        return false;
      }
    }

    return true;
  },

  /**
   * Checks if this BitArray has at least 1 bit from the mask set
   * @param array mask - The mask to check against
   * @return bool
   */
  hasAny: function (mask) {
    return !this.and(mask).equals(0);
  },

  /**
   * Checks if this BitArray has any of the given flags set
   * @param array flags - The flags to check against
   * @return bool
   */
  hasAnyFlag: function (...flags) {
    return this.hasAny(BitArray.fromFlags(...flags));
  },

  /**
   * Checks if this BitArray has at least 1 bit from the masks set
   * @param array masks - The masks to check against
   * @return bool
   */
  hasAnyFromEach: function (...masks) {
    for (let mask of masks) {
      if (!this.hasAny(mask)) {
        return false;
      }
    }

    return true;
  },

  /**
   * Checks if this BitArray has the given flag set
   * @param array flag - The flag to check against
   * @return bool
   */
  hasFlag: function (flag) {
    return this.hasAll(flag);
  },

  /**
   * Inverts this BitArray after optionally extending to the specified length
   * @param number length - The length of the new BitArray
   * @return BitArray
   */
  invert: function (length) {
    let thisCopy = new BitArray(this);
    thisCopy.extendTo(length || thisCopy.length);

    for (let i = 0, l = thisCopy.length; i < l; i++) {
      thisCopy.bits[i] = thisCopy.bits[i] === 0 ? 1 : 0;
    }

    return thisCopy;
  },

  /**
   * ORs this and the given mask together to produce a new BitArray
   * @param array|number|BitArray|string mask
   * @return BitArray
   */
  or: function (mask) {
    let [thisCopy, maskCopy] = this._equalize(mask);

    let arr = [];
    for (let i = 0, l = thisCopy.length; i < l; i++) {
      arr.push(thisCopy.bits[i] | maskCopy.bits[i]); // eslint-disable-line no-bitwise
    }

    thisCopy.bits = arr;
    return thisCopy;
  },

  /**
   * Shifts the bits to the left by the given amount
   * @param number amount - The amount to shift by
   * @return BitArray
   */
  shiftLeft: function (amount) {
    let thisCopy = new BitArray(this);
    for (let i = 0, l = amount; i < l; i++) {
      thisCopy.bits.push(0);
      thisCopy.bits.shift();
    }

    return thisCopy;
  },

  /**
   * Shifts the bits to the right by the given amount
   * @param number amount - The amount to shift by
   * @return BitArray
   */
  shiftRight: function (amount) {
    let thisCopy = new BitArray(this);
    for (let i = 0, l = amount; i < l; i++) {
      thisCopy.bits.pop();
    }

    return thisCopy;
  },

  /**
   * Outputs the flags that compose this BitArray
   * @return array
   */
  toFlags: function () {
    let thisCopy = new BitArray(this);
    let flags = [];
    let pad = 0;

    while (thisCopy.bits.length) {
      let bit = thisCopy.bits[thisCopy.bits.length - 1];
      if (bit) {
        flags.push(bit + new Array(pad + 1).join('0'));
      }

      thisCopy = thisCopy.shiftRight(1);
      pad++;
    }

    return flags;
  },

  /**
   * Gets the string representation of this BitArray
   * @return string
   */
  toJSON: function () {
    return this.toString();
  },

  /**
   * Gets the string representation of this BitArray
   * @return string
   */
  toString: function () {
    return this.bits.join('');
  },

  /**
   * Removes any trailing 0 bits from the front of the BitArray
   * @return BitArray
   */
  trim: function () {
    let thisCopy = new BitArray(this);
    while (thisCopy.bits[0] === 0) {
      thisCopy.bits.shift();
    }

    return thisCopy;
  },

  /**
   * XORs this and the given mask together to produce a new BitArray
   * @param array|number|BitArray|string mask
   * @return BitArray
   */
  xor: function (mask) {
    let [thisCopy, maskCopy] = this._equalize(mask);

    let arr = [];
    for (let i = 0, l = thisCopy.length; i < l; i++) {
      arr.push(thisCopy.bits[i] ^ maskCopy.bits[i]); // eslint-disable-line no-bitwise
    }

    thisCopy.bits = arr;
    return thisCopy;
  },
};
