import React from 'react';

export default function({ items, onChange }) {
  return (
    <div style={styles.container}>
      {items.map((item) => (
        <label key={item.flag} style={styles.label}>
          <span>{item.label}</span>
          <input
            checked={item.checked}
            onChange={() => onChange(item.flag)}
            type="checkbox"
          />
        </label>
      ))}
    </div>
  );
}

const styles = {
  container: {
    margin: '15px',
  },
  label: {
    cursor: 'pointer',
    display: 'inline-block',
    margin: '5px',
    width: '180px',
  },
};
