import React, { useEffect, useState } from 'react';

import s3 from '../s3';

export default function () {
  const [clearing, setClearing] = useState(false);

  useEffect(() => {
    (async () => {
      if (clearing) {
        try {
          await s3.invalidateCache();
          window.success('Cache has been cleared.');
        } catch (err) {
          console.error(err);
          window.error('Could not clear cache.');
        }

        setClearing(false);
      }
    })();
  }, [clearing, setClearing]);

  return (
    <button
      disabled={clearing}
      onClick={() => {
        if (clearing) {
          return;
        }

        setClearing(true);
      }}
      style={styles.button}
    >
      {clearing ? 'Clearing...' : 'Clear Cache'}
    </button>
  );
}

const styles = {
  button: {
    position: 'absolute',
    right: '8px',
    top: '8px',
  },
};
