import React, { useRef, useState } from 'react';

import BitArray from '../BitArray';
import s3 from '../s3';
import { damageTypes, itemTypes } from '../itemConsts';
import ItemMaskGenerator from './ItemMaskGenerator';
import S3Image from './S3Image';

export default function ({ packs, onDelete, onSave }) {
  const [originalId, setOriginalId] = useState(null);
  const [tempPack, setTempPack] = useState(null);
  const [tempImage, setTempImage] = useState(null);

  const imageRef = useRef(null);
  const thumbnailRef = useRef(null);

  const filteredPacks = packs.filter(
    (pack) => pack.id !== 'com.knightsofvasteel.magwasmagicitemcompendium',
  );

  let newVersionRequired = false;

  return (
    <>
      {tempPack === null && tempImage === null && (
        <>
          <button
            onClick={() => {
              setOriginalId(null);
              setTempPack({
                description: '',
                id: '',
                images: [],
                title: '',
                version: 0,
              });
            }}
            type="button"
          >
            + Add Pack
          </button>
          <div>
            <div>Count: {filteredPacks.length}</div>
            {filteredPacks
              .sort((a, b) => (a.title > b.title ? 1 : -1))
              .map((pack) => (
                <div
                  key={pack.id}
                  onClick={() => {
                    setOriginalId(pack.id);
                    setTempPack({ ...pack });
                  }}
                  style={styles.row}
                >
                  {pack.title} - {pack.id}
                </div>
              ))}
          </div>
        </>
      )}
      {tempPack !== null && tempImage === null && (
        <>
          <div>Version: {tempPack.version}</div>
          <input
            onChange={(e) =>
              setTempPack({ ...tempPack, title: e.target.value })
            }
            placeholder="Title"
            style={styles.input}
            value={tempPack.title}
          />
          <input
            onChange={(e) => setTempPack({ ...tempPack, id: e.target.value })}
            placeholder="ID"
            style={styles.input}
            value={tempPack.id}
          />
          <textarea
            onChange={(e) =>
              setTempPack({
                ...tempPack,
                description: e.target.value,
              })
            }
            placeholder="Description"
            rows="10"
            style={styles.textarea}
            value={tempPack.description}
          />
          <div>
            {tempPack.images.map((image, index) => (
              <div key={index} style={styles.imageContainer}>
                <S3Image
                  description={`${index} - ${image.damageType || 0}:${
                    image.itemType
                  }`}
                  path={`public/magwas-magic-item-compendium/${tempPack.id}/${index}.png`}
                  title={`${damageTypes.decode(
                    image.damageType || 0,
                  )}\n${itemTypes.decode(image.itemType)}`}
                />
                <button
                  onClick={() =>
                    setTempImage({
                      ...image,
                      index: index,
                    })
                  }
                  type="button"
                >
                  Edit Image
                </button>
                {index + 1 === tempPack.images.length && (
                  <button
                    onClick={async () => {
                      if (window.confirm('Delete?')) {
                        // delete image
                        await s3.deleteObject(
                          `protected/magwas-magic-item-compendium/${tempPack.id}/${index}.png`,
                        );

                        // delete thumbnail
                        await s3.deleteObject(
                          `public/magwas-magic-item-compendium/${tempPack.id}/${index}.png`,
                        );

                        let newPack = {
                          ...tempPack,
                          images: tempPack.images.slice(0, -1),
                          version: tempPack.version + 1,
                        };

                        onSave({
                          ...newPack,
                          originalId: originalId || newPack.id,
                        });
                        setTempPack(newPack);
                      }
                    }}
                    type="button"
                  >
                    Delete Image
                  </button>
                )}
              </div>
            ))}
            <button
              onClick={() =>
                setTempImage({
                  index: tempPack.images.length,
                  itemType: null,
                })
              }
              type="button"
            >
              + Add Image
            </button>
          </div>
          <button
            onClick={() => {
              tempPack.title = tempPack.title.trim();
              if (tempPack.title === '') {
                window.error('Title is required.');
                return;
              }

              tempPack.id = tempPack.id.trim();
              if (tempPack.id === '') {
                window.error('ID is required.');
                return;
              }

              tempPack.description = tempPack.description.trim();
              if (tempPack.description === '') {
                window.error('Description is required.');
                return;
              }

              onSave(
                Object.assign({}, tempPack, {
                  originalId: originalId || tempPack.id,
                }),
              );

              setTempPack(null);
            }}
            type="button"
          >
            Save
          </button>
          <button onClick={() => setTempPack(null)} type="button">
            Cancel
          </button>
          {tempPack.index !== null && (
            <button
              onClick={async () => {
                if (window.confirm('Delete?')) {
                  // delete images
                  for (
                    let index = 0, l = tempPack.images.length;
                    index < l;
                    index++
                  ) {
                    await s3.deleteObject(
                      `protected/magwas-magic-item-compendium/${tempPack.id}/${index}.png`,
                    );
                    await s3.deleteObject(
                      `public/magwas-magic-item-compendium/${tempPack.id}/${index}.png`,
                    );
                  }

                  onDelete(tempPack.id);
                  setTempPack(null);
                }
              }}
              type="button"
            >
              Delete
            </button>
          )}
        </>
      )}
      {tempImage !== null && (
        <>
          <div>Pack: {tempPack.title}</div>
          <ItemMaskGenerator
            mask={tempImage.damageType}
            onGenerate={(damageType) => {
              const tag = damageType.toString();
              if (tag) {
                setTempImage({
                  ...tempImage,
                  damageType: tag,
                });
              } else {
                // remove damageType from the object if it's not set
                const { damageType, ...nextImage } = tempImage;
                setTempImage(nextImage);
              }
            }}
            type="damageType"
          />
          <ItemMaskGenerator
            mask={tempImage.itemType}
            onGenerate={(itemType) =>
              setTempImage({
                ...tempImage,
                itemType: itemType.toString(),
              })
            }
            type="itemType"
          />
          <div>
            Image:{' '}
            <input
              onChange={() => (newVersionRequired = true)}
              ref={imageRef}
              type="file"
            />
          </div>
          <S3Image
            path={`protected/magwas-magic-item-compendium/${tempPack.id}/${tempImage.index}.png`}
          />
          <div>
            Thumbnail:{' '}
            <input
              onChange={() => (newVersionRequired = true)}
              ref={thumbnailRef}
              type="file"
            />
          </div>
          <S3Image
            path={`public/magwas-magic-item-compendium/${tempPack.id}/${tempImage.index}.png`}
          />
          <div>
            <button
              onClick={async () => {
                if (
                  !itemTypes.mask.hasAny(tempImage.itemType) ||
                  new BitArray(tempImage.itemType).toFlags().length > 1
                ) {
                  window.error('Must choose a single item type.');
                  return;
                }

                let imageKey = `protected/magwas-magic-item-compendium/${tempPack.id}/${tempImage.index}.png`;
                let imageExists = await s3.exists(imageKey);

                let imageFile = imageRef.current.files[0];
                let imageFileIsValid =
                  imageFile &&
                  imageFile.type === 'image/png' &&
                  imageFile.size !== 0;

                if (imageFile && !imageFileIsValid) {
                  window.error('Image must be a png.');
                  return;
                }

                if (!imageExists && !imageFile) {
                  window.error('Image is required.');
                  return;
                }

                let thumbnailKey = `public/magwas-magic-item-compendium/${tempPack.id}/${tempImage.index}.png`;
                let thumbnailExists = await s3.exists(thumbnailKey);

                let thumbnailFile = thumbnailRef.current.files[0];
                let thumbnailFileIsValid =
                  thumbnailFile &&
                  thumbnailFile.type === 'image/png' &&
                  thumbnailFile.size !== 0;

                if (thumbnailFile && !thumbnailFileIsValid) {
                  window.error('Thumbnail must be a png.');
                  return;
                }

                if (!thumbnailExists && !thumbnailFile) {
                  window.error('Thumbnail is required.');
                  return;
                }

                if (imageFile) {
                  await s3.putFile(imageKey, imageFile);
                }

                if (thumbnailFile) {
                  await s3.putFile(thumbnailKey, thumbnailFile);
                }

                let newPack = {
                  ...tempPack,
                  images: tempPack.images.slice(),
                  version: tempPack.version + (newVersionRequired ? 1 : 0),
                };

                newVersionRequired = false;

                let newImage = {};
                if (tempImage.damageType) {
                  newImage.damageType = tempImage.damageType;
                }

                newImage.itemType = tempImage.itemType;

                if (tempImage.index === newPack.images.length) {
                  newPack.images.push(newImage);
                } else {
                  newPack.images[tempImage.index] = newImage;
                }

                onSave({ ...newPack, originalId: originalId || newPack.id });
                setTempPack(newPack);
                setTempImage(null);
              }}
              type="button"
            >
              Save
            </button>
            <button onClick={() => setTempImage(null)} type="button">
              Cancel
            </button>
          </div>
        </>
      )}
    </>
  );
}

const styles = {
  imageContainer: {
    display: 'inline-block',
    margin: '15px',
  },
  input: {
    display: 'block',
    width: '300px',
  },
  radio: {
    margin: '0 5px',
  },
  row: {
    borderBottom: '1px solid #000',
    cursor: 'pointer',
    padding: '10px',
  },
  search: {
    display: 'block',
  },
  textarea: {
    width: '100%',
  },
  token: {
    backgroundColor: '#eee',
    margin: '0 5px',
    padding: '0 5px',
  },
};
