import React, { useRef, useState } from 'react';

import s3 from '../s3';
import S3Image from './S3Image';

export default function ({ promo, onSave }) {
  const [divKey, setKey] = useState(1);
  const [tempPromo, setTempPromo] = useState({
    subtitle: promo.subtitle,
    title: promo.title,
    url: promo.url,
  });

  const fileRef = useRef(null);

  return (
    <div key={divKey}>
      <input
        onChange={(e) =>
          setTempPromo({
            subtitle: tempPromo.subtitle,
            title: e.target.value,
            url: tempPromo.url,
          })
        }
        placeholder="Title"
        style={styles.input}
        value={tempPromo.title}
      />
      <input
        onChange={(e) =>
          setTempPromo({
            subtitle: e.target.value,
            title: tempPromo.title,
            url: tempPromo.url,
          })
        }
        placeholder="Subtitle"
        style={styles.input}
        value={tempPromo.subtitle}
      />
      <input
        onChange={(e) =>
          setTempPromo({
            subtitle: tempPromo.subtitle,
            title: tempPromo.title,
            url: e.target.value,
          })
        }
        placeholder="URL"
        style={styles.input}
        value={tempPromo.url}
      />
      <div>
        Image: <input ref={fileRef} type="file" />
      </div>
      <S3Image path={`public/magwas-magic-item-compendium/promo.png`} />
      <div>
        <button
          onClick={async () => {
            tempPromo.title = tempPromo.title.trim();
            if (tempPromo.title === '') {
              window.error('Title is required.');
              return;
            }

            tempPromo.subtitle = tempPromo.subtitle.trim();
            if (tempPromo.subtitle === '') {
              window.error('Subtitle is required.');
              return;
            }

            tempPromo.url = tempPromo.url.trim();

            let key = `public/magwas-magic-item-compendium/promo.png`;
            let imageExists = await s3.exists(key);

            let file = fileRef.current.files[0];
            let fileIsValid =
              file && file.type === 'image/png' && file.size !== 0;

            if (file && !fileIsValid) {
              window.error('Image must be a png.');
              return;
            }

            if (!imageExists && !file) {
              window.error('Image is required.');
              return;
            }

            if (file) {
              await s3.putFile(key, file);
            }

            onSave(tempPromo);
            setKey(divKey + 1);
          }}
          type="button"
        >
          Save
        </button>
        <button
          onClick={() =>
            setTempPromo({
              subtitle: promo.subtitle,
              title: promo.title,
              url: promo.url,
            })
          }
          type="button"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

const styles = {
  input: {
    display: 'block',
    width: '300px',
  },
};
